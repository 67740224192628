import React, {useState} from 'react';
import {
  Button,
  Flex,
  Text,
  TextField,
  View,
  Card,
} from "@aws-amplify/ui-react";


export default function AddFolderDetails({adder, canceler, prefix}) {
	const [newFolderName, setNewFolderName] = useState("")
	return (<View> 
		<Card>
		<TextField padding="medium" size="default"
  placeholder=""
  label="Folder Name"
	onChange={(e) => {setNewFolderName(e.currentTarget.value)}}
/>
        <Flex padding="medium">
        <Text lineHeight={3}>{prefix}</Text>
        <Button  onClick={() => adder(newFolderName)} >
	 Save
        </Button>
        <Button  onClick={() => canceler()} >
	  Cancel
        </Button>
        </Flex>
		</Card>
		</View>)
}
