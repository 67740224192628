import { useState, useEffect } from 'react';
import {Storage} from 'aws-amplify';
import {
  Button,
  Flex,
  Text,
  TextField,
  View,
  Card,
} from "@aws-amplify/ui-react";

function Video({signedUrl, posterUrl, portrait}) {
     if (portrait) {
     return <View class="imgfit" padding="xs" style={{margin: "auto"}}><video class="s3videoportrait" src={signedUrl} controls playsinline poster={posterUrl} controlsList="nodownload" preload="none" style={{overflow:"inherit", WebkitBorderRadius:"3%"}}/></View>
     }
     else {
     return <View class="imgfit" padding="xs" style={{margin: "auto"}}><video class="s3videolandscape" src={signedUrl} controls playsinline poster={posterUrl} controlsList="nodownload" preload="none" style={{overflow:"inherit", WebkitBorderRadius:"3%"}}/></View>
     }
}

export default function AddVideoFromS3({filename, isProtected, cognitoId}) {
     const [signedUrl, setSignedUrl] = useState("")
     const [posterUrl, setPosterUrl] = useState("")
     var s3filename = filename
     var width = ""
     var height = ""
     var portrait = false
     if (typeof filename === "object") {
         s3filename = filename.url.S
         if (!s3filename) {
             s3filename = filename.url
             width = parseInt(filename.width)
             height = parseInt(filename.height)
	 } else {
             width = parseInt(filename.width.S)
             height = parseInt(filename.height.S)
	 }
         portrait = (height > width)
     }
     useEffect(() => {
         async function getSignedUrl() {
             var url = ""
             if (isProtected) {
                 url = await Storage.vault.get(s3filename, {expires: 84600, level: "protected", identityId:cognitoId})
             }
             else {
                 url = await Storage.vault.get(s3filename, {expires: 84600})
             }
             setSignedUrl(url)
             var poster = ""
             if (isProtected) {
                 poster = await Storage.vault.get(s3filename + ".jpg", {expires: 84600, level: "protected", identityId:cognitoId})
             }
             else {
                 poster = await Storage.vault.get(s3filename + ".jpg", {expires: 84600})
             }
             setPosterUrl(poster)
     }
     getSignedUrl()
     }, [])
     return <Video posterUrl={posterUrl} signedUrl={signedUrl} portrait={portrait}/>
}
