import {useState} from 'react';
import {
  View,
  Flex,
  Text,
  Button,
  ButtonGroup,
  TextAreaField,
} from "@aws-amplify/ui-react";

export default function AddLink({addLinkStep, cancel}) {
	const [link, setLink] = useState("")
		return <Flex direction="column"><Text> URL: </Text><input type="url" placeholder="" value={link} onChange={(e) => setLink(e.target.value)}/>
        <ButtonGroup gap="0.2 rem" size="small">
        <Button  onClick={() => {addLinkStep(link)}} size="small">
	  Add
        </Button>
        <Button  onClick={() => cancel()} size="small">
	  Cancel
        </Button>
        </ButtonGroup>
        </Flex>
}
