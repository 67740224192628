import { useState, useEffect } from 'react';
import {Storage} from 'aws-amplify';
import {
  Heading,
  Flex,
  Badge,
  Button,
  Text,
  Grid,
  View,
  Card,
} from "@aws-amplify/ui-react";
import AddVideoFromS3 from './AddVideoFromS3';
import AddAudioFromS3 from './AddAudioFromS3';
import AddImages from './AddImageFromS3';

function getBadgeColor(badgeText) {
    if (badgeText == "@child") return 'blue.10'
    if (badgeText == '@you') return 'green.10'
    if (badgeText == '@comment') return 'red.10'
    else return 'teal.20'
}

function ProcessBadge({value}) {
        return  <Badge padding="xs" backgroundColor={getBadgeColor(value)}>{value}</Badge>
}

function ProcessText({speaker, value}) {
        return <Text padding="xs" as="p" style={{whiteSpace:"pre-line"}}> {value} </Text>
}

function AddSpeakerText({speaker, text}) {
    return <View padding="xxxs">
    <ProcessBadge value={speaker} />
    <ProcessText speaker={speaker} value={text} />
    </View>
}

function AddTextNote({src}){
   return <View> <Text padding="xs" as="p" style={{whiteSpace:"pre-line"}}>{src} </Text></View>
}


function AddChat({chat}) {
    let components = []
    for (let i = 0; i < chat.length; i+= 2) {
        components.push(<AddSpeakerText speaker={chat[i]} text={chat[i+1]}/>)
    }
    return (<div> 
    <Grid
  columnGap="0"
  rowGap="0.5rem"
>
  <Card>
        {components}
        </Card>
</Grid></div>)

}

export default function ProcessConversationSteps({item, expand, itemConversations, index, setImageDivId, isProtected, cognitoId}) {
    const components = []
    let title=""
    for (let i = 0; i < itemConversations.length; i+= 2) {
        let speaker = itemConversations[i].S
        let text = itemConversations[i+1].S
        if (speaker == "title/") {
            title = text
    }
    }
    if (title.length > 0) {
        components.push(<Heading level={4} padding="xxs">{title}</Heading>)
    }
    let expandable = false;
    for (let i = 0; i < itemConversations.length; i+= 2) {
        let speaker = itemConversations[i].S
        let text = itemConversations[i+1].S
        var videoM = itemConversations[i+1].M
        if (!videoM) { videoM = text }
        if (speaker.startsWith('title/')) {
            continue;
    }
        if (speaker.startsWith("vid/")) {
            components.push(
                <AddVideoFromS3 filename={videoM} isProtected={isProtected} cognitoId={cognitoId}/>
        )
    }
        else if (speaker.startsWith('note/')) {
            components.push(<AddTextNote src={text}/>)
    }
    else if (speaker.startsWith("aud/")) {
            components.push(
                <AddAudioFromS3 filename={text}/>
        )
    } else if (speaker.startsWith("img/")) {
            let array = itemConversations[i+1].L
            let photos = []
            if (array) {
               photos = array.map(x => x.S)
        }
               else {
            photos = [text]
        }
            if (photos.length > 1) {
                expandable = true;
	    }
            components.push(<AddImages src={photos} index={index} setImageDivId={setImageDivId} isProtected={isProtected} cognitoId={cognitoId}/>)
    }
        else if (speaker.startsWith('chat/')) {
            let array = itemConversations[i+1].L
            let chat = []
            if (array) {
               chat = array.map(x => x.S)
        }
            components.push(<AddChat chat={chat}/>)
    } else if (speaker.startsWith('link/')) {
  //<iframe width="560" height="315" src={text} allowFullScreen frameborder="0" playsinline></iframe>
	    if (isYouTubeLink(text)) {
	    let url = processYoutubeLink(text)
            components.push((
  <div class="imglandscape">
		    <iframe src={url} class="youtube" frameborder="0" allowFullScreen style={{overflow:"inherit", WebkitBorderRadius:"3%"}}></iframe>
  </div>
	    ))
	    }
	    else {
		       components.push(<View padding="xs"><a href={text}>{text}</a></View>)
	    }

    } else {
        components.push(
        <div>
        <ProcessBadge value={speaker} />
        <ProcessText speaker={speaker} value={text} />
        </div>);
    }
    }

    let resultComponents = []
    resultComponents.push(components[0]) 
    if (components.length > 1) {
        resultComponents.push(components[1]) 
    }
    if (components.length > 2) {
        resultComponents.push(<Button variation="link" onClick={() => expand(item, index, isProtected, cognitoId)}> Show More </Button>)
    }
    if (components.length <= 2 && expandable) {
        resultComponents.push(<Button variation="link" onClick={() => expand(item, index, isProtected, cognitoId)}> Show More </Button>)
    }
    return (<div class="content"> {resultComponents} </div>)
}

function isYouTubeLink(inputUrl) {
    return inputUrl.includes("youtu.be/") || inputUrl.includes("youtube.com/")
}
function processYoutubeLink(inputUrl) {
    var videoId = extractVideoId(inputUrl)
    return "https://www.youtube.com/embed/" + videoId
}

// Function to extract the YouTube video ID from the URL
function extractVideoId(url) {
  var videoId = '';

  // Patterns to match different YouTube URL formats
  var patterns = [
    /youtu\.be\/([^\/]+)/,
    /youtube\.com\/watch\?v=([^\/]+)/,
    /youtube\.com\/embed\/([^\/]+)/,
    /youtube\.com\/v\/([^\/]+)/,
    /youtube\.com\/user\/[^\/]+\/([^\/]+)/
  ];

  // Check each pattern and extract the video ID if found
  for (var i = 0; i < patterns.length; i++) {
    var match = url.match(patterns[i]);
    if (match && match[1]) {
      videoId = match[1];
      break;
    }
  }

  return videoId;
}
