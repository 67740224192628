import React, { useEffect } from 'react';
import { VscBell } from "react-icons/vsc";
import {API} from 'aws-amplify';
import {Button} from "@aws-amplify/ui-react";
import { getMessaging, getToken } from "firebase/messaging";


const apiName = 'mcsApi';

export default function NotificationComponent({userid}) {

  return (
        <Button size="small" onClick={() => subscribe(userid)}><VscBell/></Button>
  );
}

function subscribe(userid) {
  if ('Notification' in window) {
    Notification.requestPermission().then((permission) => {
      if (permission === 'granted') {
        console.log('Notification permission granted.');
        const messaging = getMessaging();
        getToken(messaging, {vapidKey: "BNUx8JUNEoLDz_kwi3Wl9Y4FW6VGE87DnnG1PLkJB_S1ULW2uA8QkWY4Wnza7SD4ZyXobzpeAOA5u2Ye98GU03Y"}).then((currentToken) => {
           if (currentToken) {
              console.log("currentToken: " + currentToken)
              subscribeUserToPush(userid, currentToken)
              window.alert("subscription successful")
	   }
	});
      }
      else {
          window.alert("subscription request denied")
      }
    });
  }
}

function unused() {
  console.log("notification component called!")
// useEffect(() => {
//   // Request permission for notifications
//   if ('Notification' in window) {
//     Notification.requestPermission().then((permission) => {
//       if (permission === 'granted') {
//         console.log('Notification permission granted.');
//         subscribeUserToPush();
//       }
//     });
//   }
// }, []);
// const showNotification = () => {
//   if ('Notification' in window) {
//     if (Notification.permission === 'granted') {
//       new Notification('New Notification', {
//         body: 'This is the notification body.',
//       });
//     }
//   }
// };
//
}

function subscribeUserToPush(userid, currentToken) {
  if ('serviceWorker' in navigator && 'PushManager' in window) {
    navigator.serviceWorker.ready.then(registration => {
      registration.pushManager.getSubscription().then(sub => {
        //if (sub === null) {
          registration.pushManager
            .subscribe({
              userVisibleOnly: true,
              applicationServerKey: 'BNUx8JUNEoLDz_kwi3Wl9Y4FW6VGE87DnnG1PLkJB_S1ULW2uA8QkWY4Wnza7SD4ZyXobzpeAOA5u2Ye98GU03Y',
            })
            .then(subscription => {
              console.log('User is subscribed:', subscription);
              // Send the subscription object to your server
              sendSubscriptionToServer(subscription, userid, currentToken);
            })
            .catch(error => {
              console.error('Failed to subscribe the user:', error);
            });
        //}
      });
    });
  } 
}

function sendSubscriptionToServer(subscription, userid, currentToken) {
  // Send a POST request to your server with the subscription object
const path = '/subscribe/' + userid
const deviceid = subscription.endpoint.split('/').pop();
const init = {
	body: { "userid": userid, "deviceid": deviceid, "subscription": JSON.stringify(subscription), "registrationToken": currentToken},
    headers: {
      'Content-Type': 'application/json',
    },
}
API.post(apiName, path, init)
  .then((response) => {
    console.log("subscription completed!")
  })
  .catch((error) => {
    console.log(error.response);
  });
}
