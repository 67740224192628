import AudioRecorder from './AudioRecorderDemo';
import {
  View,
  Flex,
  Button,
  TextAreaField,
} from "@aws-amplify/ui-react";



export default function AddVoiceNote({voiceNote, setVoiceNote, addConversationStep, cancel}) {

    return <View><AudioRecorder initial={voiceNote} setter={setVoiceNote}/>
        <Flex>
        <Button  onClick={() => {addConversationStep("aud/", voiceNote.audioDetails.url, true)}} size="small">
	  Add
        </Button>
        <Button  onClick={() => cancel()} size="small">
	  Cancel
        </Button>
        </Flex>
	</View>
}
