import React, {useState, useEffect} from 'react';
import { SlNote } from "react-icons/sl";
import { FaSave } from "react-icons/fa";
import {
  Button,
  Grid,
  Flex,
  Divider,
  Heading,
  Image,
  Loader,
	ButtonGroup,
	Badge,
	Text,
  Tabs,
	TabItem,
  TextField,
	View,
  Card,
} from "@aws-amplify/ui-react";
import {Storage} from 'aws-amplify';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel'
import { IoMdArrowBack } from "react-icons/io";
import { AiOutlineArrowUp, AiOutlineArrowDown } from "react-icons/ai";
import { MdOutlineClose } from "react-icons/md";
import { CiEdit } from "react-icons/ci";

import AddConversationStep from './AddConversationStep.js'
import AddVideoFromS3 from './AddVideoFromS3.js'
import VideoInEdit from './VideoInEdit.js'

function getBadgeColor(badgeText) {
    if (badgeText == "@child") return 'blue.10'
    if (badgeText == '@you') return 'green.10'
    if (badgeText == '@comment') return 'red.10'
    else return 'teal.20'
}


export default function AddConversation({cid, linkMap, childid, adder, speakers, canceler, initial, myPostsIndex}) {
	const [conversations, setConversations] = useState(initial)
	const [videoUploadPercentage, setVideoUploadPercentage] = useState(0)
	const [videoSizeUnit, setVideoSizeUnit] = useState("0 MB")

function updateConversations(newConversations) {
    console.log("Updating conversations")
    console.log("Conversations Size = "  + newConversations.length)
    let conversationsCopy = newConversations.map(x => x)
    setConversations(conversationsCopy)
}

function updatePercent(progress) {
    var total = progress.total
    var loaded = progress.loaded
    var percent = 0
    var oneMB = 1024 * 1024
    var unit = ""
    if (total < oneMB) {
	unit = "KB"
        total = total / 1024
        loaded = loaded / 1024
    }
    else {
	unit = "MB"
        total = total / 1024
        loaded = loaded / 1024
        total = total / 1024
        loaded = loaded / 1024
    }
    percent = (loaded * 100) / total
    percent = Math.floor(percent)
    total = Math.floor(total)
    setVideoUploadPercentage(percent)
    setVideoSizeUnit("" + total + " " + unit)
}

function undo() {
   let buffer = conversations.map(x => x)
   buffer.pop()
   buffer.pop()
   setConversations(buffer)
}

function AddButtons(){
if (conversations.length == 0) { return <div> </div> }
return (
        <Flex padding="xs">
        <Button  onClick={() => undo()}>
	  Undo
        </Button>
        </Flex>)
}

function updateSaveButton() {

    let buffer=conversations.map(x=>x)
    let title=""
    let isTitleInConversation= false
    for (let i = 0; i < conversations.length; i+= 2) {
        let speaker = conversations[i]
        let text = conversations[i+1]
        if (speaker == "title/") {
            title = text
            isTitleInConversation = true
            if (i !== 0) {
                buffer.splice(i, 2)
		buffer.unshift(title)
		buffer.unshift("title/")
	    }
	}
    }
    if (!isTitleInConversation) {
       window.alert("Please add a title for this post.")
       return;
    }
    var btn = document.getElementById("savebutton");
    btn.innerHTML = 'Saving...'
    let elems_to_remove = 0
    for (let i = 0; i < buffer.length; i+= 2) {
        let speaker = buffer[i]
        let text = buffer[i+1]
        if (speaker == "title/") {
            if (i !== 0) {
                elems_to_remove += 2
	    }
	}
    }
    if (elems_to_remove > 0) {
       buffer.splice(2, elems_to_remove)
    }
    console.log("after change: ")
    console.log(buffer)
    adder(childid, buffer, myPostsIndex)
}

function First({showPreview, childid, conversations}) {
if (!showPreview) return
return(
    <div>
    <View padding="xs">
    <Card
      padding="xs"
      key="1"
      borderRadius="medium"
    >
        <ProcessConversationStepsInEditMode />
    </Card>
    </View>
	  </div>)
}

function Second({showPreview, childid, conversations}) {
if (!showPreview) return
if (conversations.length == 0) { return <div> </div> }
return(
    <View >
    <View padding="xs">
    <Card
      padding="xs"
      key="1"
      borderRadius="medium"
      variation="outlined"
    >
        <ProcessConversationSteps />
    </Card>
    </View>
	  </View>)
}

function ProcessBadge({value}) {
	return  <Badge backgroundColor={getBadgeColor(value)} padding="xs">{value}</Badge>
}
function ProcessText({speaker, value}) {
	return <Text padding="xs" as="p" style={{whiteSpace:"pre-line"}}> {value} </Text>
}

function AddImage({src}){
   return <View class="carousel-item" padding="xxxs"><Image src={src} style={{WebkitBorderRadius: "3%"}}/></View>
}

function AddImages({src}) {
            let components = []
            for (let i = 0; i < src.length; i+=1) {
                if (src[i].url) {
                components.push(<AddImage src={src[i].url} />)
		} else {
                components.push(<AddImageFromS3 filename={src[i]} index={i}/>)
		}
	    }
	    if (src.length == 1) {
		    return <View> {components} </View>
	    }
	    return <Carousel showIndicators={false}>{components}</Carousel>
}

function AddImageFromS3({filename, index}) {
     const [signedUrl, setSignedUrl] = useState("")
     useEffect(() => {
         async function getSignedUrl() {
             const url = await Storage.vault.get(filename)
             setSignedUrl(url)
	 }
	 getSignedUrl()
     }, [])
     if (index < 2) {
     return <View class="carousel-item"><Image src={signedUrl} style={{WebkitBorderRadius:"3%", overflow:"hidden"}} /></View>
     }
     return <View class="carousel-item"><Image src={signedUrl} style={{WebkitBorderRadius:"3%", overflow:"hidden"}} loading="lazy" /></View>
}

function AddAudio({src}){
   return <audio controls>
            <source src={src} type='audio/ogg' />
            <source src={src} type='audio/mpeg' />
          </audio>
}

function AddAudioFromS3({filename}){
     const [signedUrl, setSignedUrl] = useState("")
     useEffect(() => {
         async function getSignedUrl() {
             const url = await Storage.vault.get(filename)
             setSignedUrl(url)
     }
     getSignedUrl()
     }, [])
     return <View><audio src={signedUrl} controls/></View>
}

function getVideoSizeUnit() {
   if (videoUploadPercentage >= 1.0) {
       return videoSizeUnit
   }
   return ""
}

function AddVideo({src}) {
   if (typeof src === "object") {
       if (src.url.startsWith("blob")) {
        return <VideoInEdit src={src.url}/>
       }
       return <AddVideoFromS3 filename={src} />
   }
   if (src.startsWith("blob")) {
        return <VideoInEdit src={src}/>
   }
   else {
   return <AddVideoFromS3 filename={src} />
   }
}

function AddTextNote({src}){
   return <View> <Text padding="xs" as="p" style={{whiteSpace:"pre-line"}}>{src} </Text></View>
}

function AddChat({chat}) {
    let components = []
    for (let i = 0; i < chat.length; i+= 2) {
        components.push(<AddSpeakerText speaker={chat[i]} text={chat[i+1]}/>)
    }
    return (<div> 
	<Grid
  columnGap="0.5rem"
  rowGap="0.5rem"
  templateColumns="10px 1fr"
>
  <Card
    columnStart="1"
    columnEnd="2"
  >
  </Card>
  <Card>
		{components}
		</Card>
</Grid></div>)

}

function AddSpeakerText({speaker, text}) {
    return <View padding="xs">
	<ProcessBadge value={speaker} />
	<ProcessText speaker={speaker} value={text} />
	</View>
}

function ProcessConversationSteps() {
    const components = []
    if (conversations.length == 0) {
        return <Text padding="xs">Contents will appear here!</Text>
    }
    
    let title=""
    for (let i = 0; i < conversations.length; i+= 2) {
        let speaker = conversations[i]
        let text = conversations[i+1]
        if (speaker == "title/") {
            title = text
	}
    }
    if (title.length > 0) {
        components.push(<Heading level={4} padding="xs">{title}</Heading>)
    }
    for (let i = 0; i < conversations.length; i+= 2) {
        let speaker = conversations[i]
        let text = conversations[i+1]
        if (speaker.startsWith('title/')) {
            continue;
	}
        else if (speaker.startsWith('note/')) {
            components.push(<AddTextNote src={text}/>)
	}
        else if (speaker.startsWith('aud/')) {
            if (text.startsWith("blob")) {
                components.push(<AddAudio src={text} />)
	    }
		else {
                    components.push(<AddAudioFromS3 filename={text} />)
		}
	}
	    else if (speaker.startsWith('img/')) {
            let photos = text
            components.push(<AddImages src={photos} />)
	}
	else if (speaker.startsWith('vid/')) {
            components.push(<AddVideo src={text} />)
	}
	    else if (speaker.startsWith('chat/')) {
            components.push(<AddChat chat={text} />)
	}
	    else if (speaker.startsWith('link/')) {
	           console.log(text)
		    if (isYouTubeLink(text)) {
	           console.log(text + " is a youtube link")
		    let link = processYoutubeLink(text)   
		    components.push(<iframe src={link}  sandbox = "allow-forms allow-pointer-lock allow-same-origin allow-scripts allow-top-navigation" frameborder="0" allowFullScreen style={{overflow:"inherit", WebkitBorderRadius:"3%"}}></iframe>)
		    }
		    else {
		       console.log("it is not a youtube link")
		       components.push(<View padding="xs"><a href='#'>{text}</a></View>)
		    }
	}
	    else {
        components.push(<AddSpeakerText speaker={speaker} text={text} />);
	    }
    }
    return (<div> {components} </div>)
}

function moveElemUp(index) {
   console.log("in elem up")
   let buffer = conversations.map(x => x) 
   let temp_source = buffer[index]
   let temp_source_value = buffer[index+1]
   buffer[index] = conversations[index - 2]
   buffer[index + 1] = conversations[index - 1]
   buffer[index - 2] = temp_source
   buffer[index - 1] = temp_source_value
   setConversations(buffer)
}

function moveElemDown(index) {
   console.log("in elem down")
   let buffer = conversations.map(x => x) 
   let temp_source = buffer[index]
   let temp_source_value = buffer[index+1]
   buffer[index] = conversations[index + 2]
   buffer[index + 1] = conversations[index + 3]
   buffer[index + 2] = temp_source
   buffer[index + 3] = temp_source_value
   setConversations(buffer)
}

function deleteElem(index) {
   console.log("in elem delete")
   let buffer = conversations.map(x => x) 
   buffer.splice(index, 2)
   setConversations(buffer)
}


function EditButtons({index}) {
   let conversations_length = conversations.length
   let last_item_index = conversations_length - 2
   if (index === 0) {
      return (
		<div>
		<ButtonGroup size="small" variation="link" gap="0.01rem">
		<Button size="small"><CiEdit/></Button>
		<Button size="small" onClick={()=> deleteElem(index)}><MdOutlineClose/></Button>
		</ButtonGroup>
		</div>)
   }
   if (index === 2 && index === last_item_index) {
   return (
		<div>
		<ButtonGroup size="small" variation="link" gap="0.01rem">
		<Button size="small"><CiEdit/></Button>
		<Button size="small" onClick={()=> deleteElem(index)}><MdOutlineClose/></Button>
		</ButtonGroup>
		</div>)
   }
   if (index === 2) {
   return (
		<div>
		<ButtonGroup size="small" variation="link" gap="0.01rem">
		<Button size="small"><CiEdit/></Button>
		<Button size="small" onClick={()=> deleteElem(index)}><MdOutlineClose/></Button>
	        <Button size="small" onClick={() => moveElemDown(index)}><AiOutlineArrowDown/></Button>
		</ButtonGroup>
		</div>)
   }
   if (index === last_item_index) {
   return (
		<div>
		<ButtonGroup size="small" variation="link" gap="0.01rem">
		<Button size="small"><CiEdit/></Button>
		<Button size="small" onClick={()=> deleteElem(index)}><MdOutlineClose/></Button>
	        <Button size="small" onClick={() => moveElemUp(index)}><AiOutlineArrowUp/></Button>
		</ButtonGroup>
		</div>)
   }
   return (
		<div>
		<ButtonGroup size="small" variation="link" gap="0.01rem">
		<Button size="small"><CiEdit/></Button>
		<Button size="small" onClick={()=> deleteElem(index)}><MdOutlineClose/></Button>
	        <Button size="small" onClick={() => moveElemDown(index)}><AiOutlineArrowDown/></Button>
	        <Button size="small" onClick={() => moveElemUp(index)}><AiOutlineArrowUp/></Button>
		</ButtonGroup>
		</div>)
}

function Outer({inner, index}) {
   let components = []
   components.push(<Card variation="outlined"><Flex direction="column" justifyContent="space-between">
	        {inner}
	        <EditButtons index={index}/>
		</Flex></Card>)
  return components
}

function ProcessConversationStepsInEditMode() {
    const components = []
    if (conversations.length == 0) {
	return  <div></div>
    }
    console.log("processing conversations!")
    console.log(conversations)
    let title=""
    let titleIndex = 0
    for (let i = 0; i < conversations.length; i+= 2) {
        let speaker = conversations[i]
        let text = conversations[i+1]
        if (speaker == "title/") {
            title = text
            titleIndex = i
	}
    }
    if (title.length > 0) {
        let inner=<Heading level={4} padding="xs">{title}</Heading>
        components.push(<Outer inner={inner} index={titleIndex}/>)
    }
    for (let i = 0; i < conversations.length; i+= 2) {
        let speaker = conversations[i]
        let text = conversations[i+1]
        if (speaker.startsWith('title/')) {
            continue;
	}
        else if (speaker.startsWith('note/')) {
            let inner = <AddTextNote src={text}/>
            components.push(<Outer inner={inner} index={i}/>)
	}
        else if (speaker.startsWith('aud/')) {
            if (text.startsWith("blob")) {
                components.push(<AddAudio src={text} />)
	    }
		else {
                    components.push(<AddAudioFromS3 filename={text} />)
		}
	}
	    else if (speaker.startsWith('img/')) {
            let photos = text
            let inner = <AddImages src={photos} />
            components.push(<Outer inner={inner} index={i}/>)
	}
	else if (speaker.startsWith('vid/')) {
            let inner = <AddVideo src={text} />
            components.push(<Outer inner={inner} index={i}/>)
	}
	    else if (speaker.startsWith('chat/')) {
            let inner = <AddChat chat={text} />
            components.push(<Outer inner={inner} index={i}/>)
	}
	    else if (speaker.startsWith('link/')) {
            let inner = <AddLink text={text} />
            components.push(<Outer inner={inner} index={i}/>)
	}
	    else {
        components.push(<AddSpeakerText speaker={speaker} text={text} />);
	    }
    }
    console.log("components!")
    console.log(components)
    return (<div> {components} </div>)
}

function AddLink({text}){
  if (isYouTubeLink(text)) {
 console.log(text + " is a youtube link")
  let link = processYoutubeLink(text)   
  return <iframe src={link}  frameborder="0" allowFullScreen style={{overflow:"inherit", WebkitBorderRadius:"3%"}}></iframe>
  }
  else {
     console.log("it is not a youtube link")
     return <View padding="xs"><a href='#'>{text}</a></View>
  }
}
function isYouTubeLink(inputUrl) {
    return typeof inputUrl !== 'undefined' && (inputUrl.includes("youtu.be/") || inputUrl.includes("youtube.com/"))
}
function processYoutubeLink(inputUrl) {
    var videoId = extractVideoId(inputUrl)
    return "https://www.youtube.com/embed/" + videoId
}

// Function to extract the YouTube video ID from the URL
function extractVideoId(url) {
  var videoId = '';

  // Patterns to match different YouTube URL formats
  var patterns = [
    /youtu\.be\/([^\/]+)/,
    /youtube\.com\/watch\?v=([^\/]+)/,
    /youtube\.com\/embed\/([^\/]+)/,
    /youtube\.com\/v\/([^\/]+)/,
    /youtube\.com\/user\/[^\/]+\/([^\/]+)/
  ];

  // Check each pattern and extract the video ID if found
  for (var i = 0; i < patterns.length; i++) {
    var match = url.match(patterns[i]);
    if (match && match[1]) {
      videoId = match[1];
      break;
    }
  }

  return videoId;
}


  const [preview, setPreview] = useState(true)

  function CreateOrEditPost() {
      if (typeof initial === 'undefined' || initial.length === 0)  {
          return <Text style={{fontSize:"1rem"}} paddingTop="small"> Create post </Text>
      }
      return <Text style={{fontSize:"1rem"}} paddingTop="small"> Edit post </Text>
  }

  function SaveButton({showPreview}) {
          if (!showPreview) return
          if (conversations.length === 0){
		  return <div></div>
	  }
          return (
          <View padding="xs">
          <Button variation="primary" size="small" id="savebutton" onClick={() => { updateSaveButton(); } } >
		  <FaSave/> 
	  <Text style={{fontSize:"0.8rem"}} color="White"> &nbsp;Save </Text>
          </Button>
          </View>)
  }

  return (
	  <div class="overall">
	  <div class="stickytop">
        <Flex justifyContent="flex-start" gap="0.1rem">
          <View padding="xs">
        <Button size="small" onClick={() => canceler()}>
	 <Text style={{fontSize:"0.8rem"}}> <IoMdArrowBack/> </Text>
        </Button>
        </View>
          <SaveButton showPreview={preview}/>
          <CreateOrEditPost/>
        </Flex>
	  </div>
	  <div class="main">
	  <Flex direction="column">
	  <First showPreview={preview} childid={childid} conversations={conversations}/>
	  <View padding="small">
	  <AddConversationStep cid={cid} uploadPercentSetter={updatePercent} linkMap={linkMap} setter={updateConversations} initial={conversations} speakers={speakers} setPreview={setPreview}></AddConversationStep>
	  </View>
	  <View style={{overflow:"hidden", webkitBorderRadius:"3%", display:"none" }}>
		<video width="100%" height="auto" id="video" controls loop style={{display:"block"}}>
  <source src="" type="video/mp4"/>
Your browser does not support the video tag.
</video></View>
	  </Flex>
	  </div>
	  </div>
  )
}
