import React, {useState} from 'react';
import {
  Button,
  Collection,
  CheckboxField,
  Flex,
  Text,
  TextField,
  View,
  Card,
} from "@aws-amplify/ui-react";


export default function MoveOrCopy({folders, adder, canceler, timestamp}) {
	const [folderList, setFolderList] = useState([])

 function updateFolders(folderName, checked) {
     var localFolders = folderList
     if (!checked) {
       localFolders = localFolders.filter(item => item !== folderName)
     } else {
       localFolders.push(folderName)
     }
     setFolderList([localFolders])
 }

	return (<View> 
         <View padding="medium"><Collection
  items={folders}
  type="list"
  direction="column"
  gap="20px"
  wrap="nowrap"
>
  {(item, index) => (
    <CheckboxField name={item.foldername} value={item.folderid} label={item.foldername} onChange={(e) => {updateFolders(e.target.value, e.target.checked)}}/>
  )}
</Collection>
</View>
                
        <Flex padding="medium">
        <Button  onClick={() => adder(folderList, timestamp)} >
	 Copy to folder
        </Button>
        <Button  onClick={() => canceler(timestamp)} >
	  Cancel
        </Button>
        </Flex>
		</View>)
}
