import {
  Button,
  Flex,
  Text,
  TextField,
  Loader,
  View,
  Card,
} from "@aws-amplify/ui-react";

export default function VideoInEdit({percent, totalSize, src}) {
        return <View style={{overflow:"hidden", WebkitBorderRadius:"3%"}}>
		       <video width="100%" height="260px" src={src} controls></video>
	       </View>
}
