import React, {useState, useRef} from 'react';
import {
  View,
  Image as AmplifyImage,
  Flex,
  Collection,
  Card,
  Button,
  TextAreaField
} from "@aws-amplify/ui-react";
import SwitchComponents from './SwitchComponents';


async function scaleImage(file: File) {
    // ensure the file is an image
    if (!file.type.match(/image.*/)) return null;

    const image = new Image();
    image.src = URL.createObjectURL(file);

    await new Promise((res) => image.onload = res);
    const canvas = document.createElement("canvas");
    const context = canvas.getContext("2d", {alpha: true});

    const max_size = 1000
    let width = image.width
    let height = image.height
    console.log("Image Height and Width")
    console.log(height)
    console.log(width)
    if (width > height) {
        if (width > max_size) {
            height *= max_size / width;
            width = max_size;
        }
    } else {
        if (height > max_size) {
            width *= max_size / height;
            height = max_size;
	}
    }

    canvas.width = width
    canvas.height = height

    context.drawImage(image, 0, 0, width, height);

    return new Promise((res) => canvas.toBlob(res, "image/jpeg"))
}

    function getURL(fileName) {
	const URL = window.URL || window.webkitURL;
        return URL.createObjectURL(fileName)
    }
    export async function handlePhotoPick(e) {
        let result = [];
	let files = Array.prototype.slice.call(e.target.files).reverse();
        for (let i = 0; i < files.length; i+= 1) {
	    let file = files[i]
	    let resizedFileBlob = await scaleImage(file)
	    let blobUrl = getURL(resizedFileBlob)
	    result.push({file: resizedFileBlob, url: blobUrl})
	}
	return result;
    }

export function AddPhoto({photos, setPhotos, addConversationStep, cancel}) {
    const [activeComponent, setActiveComponent] = useState("home")
	const dragItem = useRef();
	const dragOverItem = useRef();

const dragStart = (e, position) => {
    dragItem.current = position;
  };

	  const dragEnter = (e, position) => {
    dragOverItem.current = position;
  };

const drop = (e) => {
    const copyPhotos = [...photos];
    const dragItemContent = copyPhotos[dragItem.current];
    copyPhotos.splice(dragItem.current, 1);
    copyPhotos.splice(dragOverItem.current, 0, dragItemContent);
    dragItem.current = null;
    dragOverItem.current = null;
    setPhotos(copyPhotos);
  };

    function DisplayImages({photos}) {
            let components=[]
            for (let i = 0; i < photos.length; i+=1) {
                components.push( <View key={i} 
			onDragStart={(e) => dragStart(e, i)} 
			onDragEnter={(e) => dragEnter(e, i)}
			onDragEnd={drop}
			padding="xxxs" draggable><AmplifyImage src={photos[i].url} style={{WebkitBorderRadius: "3%"}}/></View>)
	    }
    return (<div> {components} </div>)
    }



    function Home() {
       return <div> </div>
    }

    return <View>
		<label class="image-pick">
		<input type="file" onChange={(e) => handlePhotoPick(e, setPhotos)} accept="image/*" multiple/>
		Pick Photos
		</label>
		<br/>
		<br/>
		<SwitchComponents active={activeComponent}>
		<Home name="home"/>
		<DisplayImages name="displayimages" photos={photos}/>
		</SwitchComponents>
		<DisplayImages photos={photos} />
        <Flex>
        <Button  onClick={() => {addConversationStep("img/", photos, true)}} size="small">
	  Add
        </Button>
        <Button  onClick={() => cancel()} size="small">
	  Cancel
        </Button>
        </Flex>
		</View>
}
