//import {Recorder} from 'react-voice-recorder'
import AudioRecorderImpl from './AudioRecorderImpl.js'
import 'react-voice-recorder/dist/index.css'
import './audio.css'
import React, {useState} from 'react';

export default function AudioRecorder({initial, setter}) {
    
   function handleAudioStop(data){
    console.log(data)
    setter({ audioDetails: data });
   }
   
   function handleAudioUpload(file) {
    console.log(file);
   }

function handleCountDown(data) {
    console.log(data);
}

function handleReset() {
    const reset = {
      url: null,
      blob: null,
      chunks: null,
      duration: {
        h: 0,
        m: 0,
        s: 0
      }
    };
    setter({ audioDetails: reset });
  }
  
  //mimeTypeToUseWhenRecording={`audio/webm`} // For specific mimetype.
  return <div id="recorder"><AudioRecorderImpl
    record={true}
    hideHeader={true}
    uploadButtonDisabled={true}
    title={"Create new voice note"}
    audioURL={initial.audioDetails.url}
    showUIAudio={true}
    handleAudioStop={data => handleAudioStop(data)}
    handleCountDown={data => handleCountDown(data)}
    handleReset={() => handleReset()}
    disableFullUI={false}
/></div>
}
