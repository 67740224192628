import React, {useState} from 'react';
import {
  Button,
  Collection,
  CheckboxField,
  Flex,
  Text,
  TextField,
  View,
  Card,
} from "@aws-amplify/ui-react";


export default function Share({connections, share, canceler, timestamp, childid, myPostsIndex}) {
        console.log("IN SHare: ")
        console.log(connections)
	const [selectedConnections, setSelectedConnections] = useState([])

 function updateConnections(name, checked) {
     console.log("NAME")
     console.log(name)
     console.log(checked)
     var localConnections = selectedConnections
     if (!checked) {
       localConnections = localConnections.filter(item => item.name != name)
     } else {
       let conn = connections.filter(item => item.name === name)[0]
       localConnections.push(conn)
     }
     setSelectedConnections(localConnections)
     console.log("Selected Connections")
     console.log(localConnections)
 }

	return (<View> 
         <View padding="medium"><Collection
  items={connections}
  type="list"
  direction="column"
  gap="20px"
  wrap="nowrap"
>
  {(item, index) => (
    <CheckboxField name={item.name} value={item.cuserid} label={item.name} onChange={(e) => {updateConnections(item.name, e.target.checked)}}/>
  )}
</Collection>
</View>
                
        <Flex padding="medium">
        <Button  size="small" onClick={() => share(selectedConnections, timestamp, childid, myPostsIndex)} >
	 Share
        </Button>
        <Button  size="small" onClick={() => canceler(timestamp)} >
	  Cancel
        </Button>
        </Flex>
		</View>)
}
