import {useState} from 'react';
import {
  View,
  Flex,
  Button,
  Text,
  TextField,
  Card,
  Collection,
  Grid,
} from "@aws-amplify/ui-react";
import { IoMdArrowBack } from "react-icons/io";
import { FcFolder } from "react-icons/fc";
import SwitchComponents from './SwitchComponents';
import AddFriendDetails from './AddFriendDetails';
import AddFolderDetails from './AddFolderDetails';
import AddTeacherDetails from './AddTeacherDetails';
import AddFavoriteDetails from './AddFavoriteDetails';
import ProcessConversation from './ProcessConversation';
import {API} from 'aws-amplify';

const apiName = 'mcsApi';
const myInit = {
  headers: {}, // OPTIONAL
  response: false, // OPTIONAL (return the entire Axios response object instead of only response.data)
};

export default function More({childid, canceler}) {
    const [folderPosts, setFolderPosts] = useState('')
    const [selectedFolderId, setSelectedFolderId] = useState('')
    const [favorites, setFavorites] = useState('')
    const [folders, setFolders] = useState('')
    const [friends, setFriends] = useState('')
    const [teachers, setTeachers] = useState('')
    const [activeComponent, setActiveComponent] = useState("home")

function Home() {
  return options()
}

function getTeachers() {
const path = '/teachers/' + childid
API.get(apiName, path, myInit)
  .then((response) => {
    setTeachers(response.map( item => ({ name: item.name.S, teacherid: item.teacherid.S, institute: item.institute.S})))
    setActiveComponent("teachersinfo")
  })
  .catch((error) => {
    console.log(error.response);
  });
}

function getFriends() {
const path = '/friends/' + childid
API.get(apiName, path, myInit)
  .then((response) => {
    setFriends(response.map( item => ({ name: item.name.S, friendid: item.friendid.S})))
    setActiveComponent("friendsinfo")
  })
  .catch((error) => {
    console.log(error.response);
  });
}

function getFavorites() {
const path = '/favorites/' + childid
API.get(apiName, path, myInit)
  .then((response) => {
    setFavorites(response.map( item => ({ attribute: item.attribute.S, value: item.value.S})))
    setActiveComponent("favorites")
  })
  .catch((error) => {
    console.log(error.response);
  });
}

function getFolders() {
const path = '/folders/' + childid
API.get(apiName, path, myInit)
  .then((response) => {
    let unsortedFolders = response.map( item => ({ foldername: item.foldername.S, folderid: item.folderid.S }))
    let sortedFolders = unsortedFolders.sort((a, b) => a.foldername < b.foldername)
    setFolders(sortedFolders)
    setActiveComponent("folders")
  })
  .catch((error) => {
    console.log(error.response);
  });
}


function gotoHome() {
  setFriends([])
  setTeachers([])
  setFavorites([])
  setFolders([])
  setActiveComponent("home")
}

function ShowFriendsList() {
  return (
     <div>
     <div class="stickytop">
        <Button  onClick={() => gotoHome()}>
      <IoMdArrowBack/>
        </Button>
     </div>
     <div class="main">
         <ShowFriendsListMain/>
     </div>
     </div>
  )
}
function ShowFriendsListMain() {
      if (friends.length == 0) { 
	      return <View padding="xs">
        <Button  onClick={() => setActiveComponent("addfriend")}>
      Add Friend
        </Button>
        </View>
      }
      return (<div>
         <View padding="medium"><Collection
  items={friends}
  type="list"
  direction="column"
  gap="20px"
  wrap="nowrap"
>
  {(item, index) => (
    <Card
      key={index}
      borderRadius="medium"
      variation="outlined"
    >
    <Text lineHeight={3}> {item.name} </Text> 
    </Card>
  )}
</Collection>
</View>
<Flex></Flex>
    <Card
      borderRadius="medium"
    >
        <Button  onClick={() => setActiveComponent("addfriend")}>
      Add Friend
        </Button>
    </Card>
        </div>)
}

function ShowFavorites() {
  return (
     <div>
     <div class="stickytop">
        <Button  onClick={() => gotoHome()}>
      <IoMdArrowBack/>
        </Button>
     </div>
     <div class="main">
         <ShowFavoritesMain/>
     </div>
     </div>
  )
}
function ShowFavoritesMain() {
      if (favorites.length == 0) { 
	      return <View padding="xs">
        <Button  onClick={() => setActiveComponent("addfavorite")}>
      Add Favorite
        </Button>
        </View>
      }
      return (<div>
         <View padding="medium"><Collection
  items={favorites}
  type="list"
  direction="column"
  gap="20px"
  wrap="nowrap"
>
  {(item, index) => (
    <Card
      key={index}
      borderRadius="medium"
      variation="outlined"
    >
    <Text lineHeight={3}> {item.attribute} </Text> 
    <Text lineHeight={3}> {item.value} </Text> 
    </Card>
  )}
</Collection>
</View>
<Flex></Flex>
    <Card
      borderRadius="medium"
    >
        <Flex>
        <Button  onClick={() => setActiveComponent("addfavorite")}>
      Add Favorite
        </Button>
        </Flex>
    </Card>
        </div>)
}

function pickFolder(folderId) {
    setSelectedFolderId(folderId)
    getFolderPosts(folderId)
}

function ShowFolders() {
  return (
     <div>
     <div class="stickytop">
        <Button  onClick={() => gotoHome()}>
      <IoMdArrowBack/>
        </Button>
     </div>
     <div class="main">
         <ShowFoldersMain/>
     </div>
     </div>
  )
}
function ShowFoldersMain() {
      if (folders.length == 0) { 
	      return <View padding="xs">
        <Button  onClick={() => setActiveComponent("addfolder")}>
      Add Folder
        </Button>
        </View>
      }
      return (<div>
         <View padding="medium"><Collection
  items={folders}
  type="list"
  direction="column"
  gap="20px"
  wrap="nowrap"
>
  {(item, index) => (
    <Flex>
    <Button padding="xs" onClick={() => pickFolder(item.folderid)}> <FcFolder/> {item.foldername} </Button> 
    </Flex>
  )}
</Collection>
</View>
<Flex></Flex>
    <Card
      borderRadius="medium"
    >
        <Button  onClick={() => setActiveComponent("addfolder")}>
      Add Folder
        </Button>
    </Card>
        </div>)
}

function ShowTeachersList() {
  return (
     <div>
     <div class="stickytop">
        <Button  onClick={() => gotoHome()}>
      <IoMdArrowBack/>
        </Button>
     </div>
     <div class="main">
         <ShowTeachersListMain/>
     </div>
     </div>
  )
}
function ShowTeachersListMain() {
      if (teachers.length == 0) { 
	      return <View padding="xs">
        <Button  onClick={() => setActiveComponent("addteacher")}>
      Add Teacher
        </Button>
        </View>
      }
      return (<div>
         <View padding="medium"><Collection
  items={teachers}
  type="list"
  direction="column"
  gap="20px"
  wrap="nowrap"
>
  {(item, index) => (
    <Card
      key={index}
      borderRadius="medium"
      variation="outlined"
    >
    <Text lineHeight={3}> {item.name} </Text> 
    <Text lineHeight={3}> {item.institute} </Text> 
    </Card>
  )}
</Collection>
</View>
<Flex></Flex>
    <Card
      borderRadius="medium"
    >
        <Button  onClick={() => setActiveComponent("addteacher")}>
      Add Teacher
        </Button>
    </Card>
        </div>)
}

function addFriend(friendName) {
    if (friendName=="") {
       return;
    }
const path = '/friends/' + childid
const init = {
    body: { name: friendName},
    headers: {}
}
API.post(apiName, path, init)
  .then((response) => {
    getFriends()
  })
  .catch((error) => {
    console.log(error.response);
  });
}

function addTeacher(teacher, institute) {
    if (teacher=="") {
       return;
    }
const path = '/teachers/' + childid
const init = {
    body: { name: teacher, institute: institute},
    headers: {}
}
API.post(apiName, path, init)
  .then((response) => {
    getTeachers()
  })
  .catch((error) => {
    console.log(error.response);
  });
}

function addFolder(foldername) {
    if (foldername=="") {
       return;
    }
const path = '/folders/' + childid
const init = {
	body: { foldername: foldername },
    headers: {}
}
API.post(apiName, path, init)
  .then((response) => {
    getFolders()
  })
  .catch((error) => {
    console.log(error.response);
  });
}

function addFavorite(attribute, value) {
    console.log(attribute)
    console.log(value)
    if (attribute=="") {
       return;
    }
    if (value=="") {
       return;
    }
const path = '/favorites/' + childid
const init = {
    body: { attribute: attribute, value: value},
    headers: {}
}
API.post(apiName, path, init)
  .then((response) => {
    getFavorites()
  })
  .catch((error) => {
    console.log(error.response);
  });
}

function cancelAddFriend(){
    setActiveComponent("friendsinfo")
}

function cancelAddFolder(){
    setActiveComponent("folders")
}

function cancelAddTeacher(){
    setActiveComponent("teachersinfo")
}

function cancelAddFavorite(){
    setActiveComponent("favorites")
}

function AddFriend() {
    return <AddFriendDetails adder={addFriend} canceler={cancelAddFriend}></AddFriendDetails>
}

function AddTeacher() {
    return <AddTeacherDetails adder={addTeacher} canceler={cancelAddTeacher}></AddTeacherDetails>
}

function AddFavorite() {
    return <AddFavoriteDetails adder={addFavorite} canceler={cancelAddFavorite}></AddFavoriteDetails>
}

function AddFolder() {
    return <AddFolderDetails adder={addFolder} canceler={cancelAddFolder}></AddFolderDetails>
}

function setImageDivId(something) {
}

function Conversation({childid, jsonarray}) {
    return (
        <div class="conversation" id="conversation">
        <Collection
  items={jsonarray}
  type="list"
  direction="column"
  gap="1px"
  wrap="nowrap"
>
  {(item, index) => (
    <div id={item.timestamp}>
    <Card
      key={index}
      borderRadius="xs"
      variation="outlined"
    >
    <div>
    <Text padding="xs"> {item.datestr} </Text>
    </div>
      <ProcessConversation itemConversations={item.conversation} index={index} setImageDivId={setImageDivId}/>
    </Card>
    </div>
  )}
</Collection>
</div>)
}

function timestampToDate(timestamp) {
   let date = new Date(parseInt(timestamp))
   return date.toDateString()
}

function toConversationObject(item) {
    let obj =  {
        conversation: item.conversation.L,
        datestr: timestampToDate(item.timestamp.S),
        timestamp: item.timestamp.S
    }
    return obj
}

function getFolderPosts(folderId) {
const path = '/folderposts/' + folderId
API.get(apiName, path, myInit)
  .then((response) => {
      let result = response.map(item => toConversationObject(item));
    setFolderPosts(result)
    setActiveComponent("listfolder")
  })
  .catch((error) => {
    console.log(error.response);
  });
}

function ListFolder() {
    return <View>
		<Conversation childid={selectedFolderId} jsonarray={folderPosts} />
		<Card>
                <Flex justifyContent="center">
		<Button onClick={() => setActiveComponent("folders")}> Back </Button>
		</Flex>
		</Card>
		</View>
}

    function options() {
		return <View padding="xs">
    <Grid columnGap="0" >
    <Card>
        <Button  onClick={() => getFavorites()}>
	  Favorites
        </Button>
    </Card>
    <Card>
        <Button  onClick={() => getFolders()}>
	  Folders
        </Button>
    </Card>
    <Card>
        <Button  onClick={() => getFriends()}>
	  Friends
        </Button>
    </Card>
    <Card>
        <Button  onClick={() => getTeachers()}>
	  Teachers
        </Button>
    </Card>
    </Grid>
    </View>
    }

  return (
      <div>
      <div class="stickytop">
        <Button  onClick={() => canceler()}>
          <IoMdArrowBack/>
        </Button>
      </div>
      <div class="main">
      <SwitchComponents active={activeComponent}>
      <Home name="home" />
      <ShowFriendsList name="friendsinfo" />
      <ShowTeachersList name="teachersinfo" />
      <ShowFavorites name="favorites" />
      <ShowFolders name="folders" />
      <AddFriend name="addfriend" />
      <AddTeacher name="addteacher" />
      <AddFavorite name="addfavorite" />
      <AddFolder name="addfolder" />
      <ListFolder name="listfolder" />
      </SwitchComponents>
      </div>
      </div>
  );
}
