import { v4 as uuidv4 } from 'uuid';
import logo from "./logo.svg";
import "@aws-amplify/ui-react/styles.css";
import { useState, useEffect, memo } from 'react';
import { AiOutlineHome, AiFillHeart } from "react-icons/ai";
import { SlHeart } from "react-icons/sl";
import {MdDriveFileMoveOutline, MdLogout, MdOutlineClose} from 'react-icons/md';
import { CiEdit } from "react-icons/ci";
import { SlNote } from "react-icons/sl";
import { IoMdContacts, IoMdArrowBack } from "react-icons/io";
import { MdOpenInNew } from "react-icons/md";
import { FiShare2 } from "react-icons/fi";
import { TfiPencilAlt } from "react-icons/tfi";
import { BsGear } from "react-icons/bs";
import { IconContext } from "react-icons";
import { MdViewHeadline, MdRefresh } from "react-icons/md";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { renderToString } from 'react-dom/server';
import { getBorderColor } from './Color.js';
import {
  withAuthenticator,
  Divider,
  Grid,
  Tabs,
  Menu,
  MenuItem,
  TabItem,
    Badge,
  Button,
  ButtonGroup,
  Flex,
  Heading,
  Text,
  TextField,
  Image,
  Card,
  Expander,
  ExpanderItem,
  View,
  useTheme,
  Collection
} from "@aws-amplify/ui-react";
import SwitchComponents from './SwitchComponents';
import AddChildDetails from './AddChildDetails';
import AddConversation from './AddConversation';
import AddConnectionDetails from './AddConnection';
import ConnectionRequest from './ConnectionRequest';
import AcceptConnectionRequest from './AcceptConnectionRequest';
import ExpandConversation from './ExpandConversation';
import MoveOrCopy from './MoveOrCopy';
import Share from './Share';
import More from './More';
import {API, Storage} from 'aws-amplify';
import ProcessConversation from './ProcessConversation';
import {openMenu, closeMenu} from './Menu';
import NotificationComponent from "./NotificationComponent";
import { initializeApp } from 'firebase/app';

const apiName = 'mcsApi';
const myInit = {
  headers: {}, // OPTIONAL
  response: false, // OPTIONAL (return the entire Axios response object instead of only response.data)
};

const firebaseConfig = {

  apiKey: "AIzaSyCVEDmhrKUsKY9mW62xitZtowZ18i9yDrg",

  authDomain: "mychildsays-84196.firebaseapp.com",

  projectId: "mychildsays-84196",

  storageBucket: "mychildsays-84196.appspot.com",

  messagingSenderId: "854848282631",

  appId: "1:854848282631:web:8f211fc1ddb28c531bcaa2",

  measurementId: "G-2NYQBNCVDE"

};

const app = initializeApp(firebaseConfig);


function App({ signOut, user }) {
    const [activeComponent, setActiveComponent] = useState("home")
    const [childid, setChildId] = useState("")
    const [likes, setLikes] = useState("")
    const [connections, setConnections] = useState([])
    const [connection, setConnection] = useState("")
    const [folders, setFolders] = useState("")
    const [conversations, setConversations] = useState('')    
    const [sharedPosts, setSharedPosts] = useState('')    
    const [myPosts, setMyPosts] = useState('')    
    const [myPostsIndex, setMyPostsIndex] = useState('')    
    const [tabIndex, setTabIndex] = useState(0)    
    const [children, setChildren] = useState('')
    const [expandedConversation, setExpandedConversation] = useState('')
    const [conversationTimestamp, setConversationTimestamp] = useState('')
    const [cognitoId, setCognitoId] = useState('')
    const [protectedVal, setProtectedVal] = useState(false)
    
let imageDivId = ''

function setImageDivId(divid) {
    imageDivId = divid
}



let imageMap = new Map();

function resetBackground() {
    const fullPage = document.getElementById('fullpage');
    fullPage.style.display = 'none';
    const convo = document.getElementById('conversation2')
    convo.style.display = 'block';
    const imgDiv = document.getElementById(imageDivId)
    imgDiv.scrollIntoView();
}

function backToMainView(elemId) {
    if (!elemId) {
        elemId = conversationTimestamp;
    }
    setActiveComponent("childinfo")
	setTimeout(function(){
    const elemDiv = document.getElementById(elemId)
    elemDiv.scrollIntoView();
        
    }, 200);
}

function AddStickyBackHeader({action}) {
       return (
        <div class="stickytop">
        <Flex justifyContent="flex-start">
        <Button variation="link" onClick={action}>
      <IoMdArrowBack/> 
        </Button>
        <Image src="atoz.ico"/>
        </Flex>
        </div>)
}

function moveToExpanded(item, index, isProtected, cognitoId) {
    setExpandedConversation(item.conversation);
    setConversationTimestamp(item.timestamp);
    setProtectedVal(isProtected);
    setCognitoId(cognitoId);
    imageDivId = item.timestamp
    setActiveComponent("showexpanded");
}

function moveToCopy(item, index) {
    setExpandedConversation(item.conversation);
    setConversationTimestamp(item.timestamp);
    setMyPostsIndex(index);
    setActiveComponent("moveorcopy");
}

function moveToShare(item, index) {
    setExpandedConversation(item.conversation);
    setConversationTimestamp(item.timestamp);
    setMyPostsIndex(index);
    setActiveComponent("share");
}

function moveToEditConversation(item, index) {
    setExpandedConversation(item.conversation);
    setConversationTimestamp(item.timestamp);
    setMyPostsIndex(index);
    setActiveComponent("editconversation");
}

function moveToDeleteConversation(item) {
    setExpandedConversation(item.conversation);
    setConversationTimestamp(item.timestamp);
    setActiveComponent("deleteconversation");
}

function moveToShowLikes(item) {
    setExpandedConversation(item.conversation);
    setConversationTimestamp(item.timestamp);
let postid = user.attributes.sub + "/" + user.attributes.sub + "/" + item.timestamp
const path = '/likes/' + postid
API.get(apiName, path, myInit)
  .then((response) => {
      let result= response
      //let result = response.map(item => toConversationObject(item));
    setLikes(result)
    setActiveComponent("showlikes");
  })
  .catch((error) => {
    console.log(error.response);
  });
}

function IsShared({item}) {
  if (item.is_shared) {
     return  (
        <Button size="small" variation="link" padding="xs" onClick={() => moveToShowLikes(item)}>
      <Badge backgroundColor= "teal.20" style={{fontSize:"0.8rem"}}>shared</Badge>
        </Button>)
  }
  return (<div> </div>
  )
}

function LikeTimestamp(item) {
   var liked = false
   if (item.item.liked !== undefined && item.item.liked.S ==="true") {
       liked = true
   }
   if (liked) {
        return <Text> liked at {timestampToDate(item.item.likets? item.item.likets.S: "")}</Text>
   }
	else {
	    return <div></div>
	}
}

function ShowSharesAndLikes() {
    return (
        <div>
        <div class="stickytop">
        <Flex>
        <Button variation="link" size="small" onClick={() => backToMainView(conversationTimestamp)}>
      <IoMdArrowBack/>
        </Button>
        </Flex>
        </div>
        <div class="main">
        <Text padding="medium"> Shared With: </Text>
        <Collection
  items={likes}
  type="list"
  direction="column"
  gap="1px"
  wrap="nowrap"
>
  {(item, index) => (
    <Card>
    <Flex padding="medium" justifyContent="space-between">
    <Text>{getConnectionNameByEmail(item.cemail.S)} </Text>
    <Text>{timestampToDate(item.timestamp? item.timestamp.S: "")} </Text>
    <LikeTimestamp item={item}/>
    </Flex>
    </Card>)}
    </Collection>
    </div>
	  </div>)
}

function EditButton({item, index}) {
	if (item.is_shared) return (<></>)
	return (<Button variation="link" size="small" padding="xs" onClick={() => moveToEditConversation(item, index)}>
      <CiEdit/>
        </Button>)
}

function Conversation({childid, jsonarray}) {
             //<Button onClick={() => moveToCopy(item, index)}>
              // <MdDriveFileMoveOutline/> &nbsp;Copy to Folder
             //</Button>
	// <Menu menuAlign="start" size="small">
    return (
        <div>
        <div class="conversation2" id="conversation2">
        <Collection
  items={jsonarray}
  type="list"
  direction="column"
  gap="0.1rem"
  wrap="nowrap"
>
  {(item, index) => (
    <div id={item.timestamp} class="scrollable">
    <Card  backgroundColor={getBorderColor(index)} variation="elevated" gap="0.1rem">
    <Card
      key={index}
      borderRadius="large"
      gap="0.1rem"
    >
        <div style={{textAlign:"right"}}>
        <Flex justifyContent="space-between">
        <IsShared item={item}/>
        </Flex>
    <Text padding="xs"> {item.datestr} </Text>
    </div>
      <ProcessConversation item={item} expand={moveToExpanded} itemConversations={item.conversation} index={index} setImageDivId={setImageDivId}/>
        <div>
        <Flex padding="small" justifyContent="right">
             <Button size="small" onClick={() => moveToEditConversation(item, index)}>
                <CiEdit/>
             </Button>
             <Button size="small" onClick={() => moveToShare(item, index)}>
                <FiShare2/>
             </Button>
        </Flex>
        </div>
    </Card>
    </Card>
    </div>
  )}
</Collection>
</div>
</div>

        )
}
function transitionAddConversation(childid) {
    setChildId(childid);
    setExpandedConversation("")
    setConversationTimestamp("")
    setActiveComponent("addconversation");
}
function transitionMore(childid) {
    setChildId(childid);
    setExpandedConversation("")
    setConversationTimestamp("")
    setActiveComponent("more");
}

function updateShowPostsButton(index) {
    var btn = document.getElementById("showposts" + index);
    btn.innerHTML = 'Loading...'
}

function showPostsIdFromIndex(index) {
    return "showposts" + index;
}

function getConnectionName(cid) {
   let cname = connections.filter(c => c.cuserid === cid)[0].name
   return cname
}

function getConnectionNameByEmail(cemail) {
   let cname = connections.filter(c => c.cemail === cemail)[0].name
   return cname
}


function callLikeApi(index) {
   let item = sharedPosts[index] 
const path = '/likes/' + user.attributes.sub
const init = {
	body: { cuserid: item.cuserid, timestamp: item.timestamp, liked: item.liked, childid: item.childid, cemail: user.attributes.email, conversation: item.conversation},
    headers: {}
}
API.post(apiName, path, init)
  .then((response) => {
    let button = document.getElementById("likebutton" + index)
    button.innerHTML = item.liked ? renderToString(<RedHeart/>): renderToString(<NormalHeart/>);
    sharedPosts[index].liked = item.liked
  })
  .catch((error) => {
    console.log(error.response);
  });
}

function flipLike(index) {
    let localConversations = sharedPosts;
    localConversations[index].liked = !localConversations[index].liked 
    setSharedPosts(localConversations)
    callLikeApi(index)
}

function RedHeart() {
	 return  <IconContext.Provider value={{ color: "orangered" }}>
          <AiFillHeart/>
          </IconContext.Provider>
}

function NormalHeart() {
    return      <SlHeart/>
}

function LikeButton({liked}) {
   if (liked) {
      return <RedHeart/>
   }
   return <NormalHeart/>
}

function SharedPosts() {
    let jsonarray = sharedPosts
    return (
        <div>
        <div class="conversation2" id="conversation2">
        <Collection
  items={jsonarray}
  type="list"
  direction="column"
  gap="1px"
  wrap="nowrap"
>
  {(item, index) => (
    <div id={item.timestamp} class="scrollable">
    <Card borderRadius="xs" variation="outlined" backgroundColor={getBorderColor(index)}>
    <Card
      key={index}
      borderRadius="large"
      gap="0.1rem"
      variation="outlined"
    >
    <div>
    <Flex justifyContent="space-between">
    <Text>Shared by {getConnectionName(item.cuserid)} </Text>
    <Text padding="xs"> {item.datestr} </Text>
    </Flex>
    </div>
      <ProcessConversation item={item} expand={moveToExpanded} itemConversations={item.conversation} index={index} setImageDivId={setImageDivId} isProtected={true} cognitoId={item.ccognitoid}/>
<Flex padding="xs" justifyContent="right" >
       <Button size="small" id={"likebutton" + index} className="likebutton" onClick={() => flipLike(index)}>
       <LikeButton liked={item.liked}/>
       </Button>
</Flex>
    </Card>
    </Card>
    </div>
  )}
</Collection>
</div>
</div>)
}

const SharedPostsMemo = memo(SharedPosts)


function showSharedPosts() {
    
const path = '/sharedposts/' + user.attributes.sub
API.get(apiName, path, myInit)
  .then((response) => {
      let result = response.map(item => toConversationObject(item));
    setConversations(result)
    setActiveComponent("sharedposts");
  })
  .catch((error) => {
    console.log(error.response);
  });
}

function populateSharedPosts() {
const path = '/sharedposts/' + user.attributes.sub
API.get(apiName, path, myInit)
  .then((response) => {
      let result = response.map(item => toConversationObject(item));
    setSharedPosts(result)
  })
  .catch((error) => {
    console.log(error.response);
  });
}

function populateMyPosts() {
const path = '/conversations/' + user.attributes.sub
//const path = '/conversations/' + children[3].childid
API.get(apiName, path, myInit)
  .then((response) => {
      let result = response.map(item => toConversationObject(item));
    setMyPosts(result)
  })
  .catch((error) => {
    console.log(error)
    console.log(error.response);
  });
}

function ExpanderItemChild({child, index, item, getConversations}) {
	return (
	   <ExpanderItem title={child} value={child}>
    <Flex direction="row">
        <ButtonGroup size="small" variation="link" direction="column" justifyContent="flex-start" isFullWidth={false}>
        <Button  id={showPostsIdFromIndex(index)} onClick={() => { updateShowPostsButton(index); getConversations(item.childid);}}>
      Posts
        </Button>
        <Button  onClick={() => transitionMore(item.childid)}>
      Milestones 
        </Button>
        <Button  onClick={() => transitionMore(item.childid)}>
      Highlights
        </Button>
        <Button  onClick={() => transitionMore(item.childid)}>
      Fun Conversations
        </Button>
        <Button  onClick={() => transitionMore(item.childid)}>
      Tags
        </Button>
        <Divider/>
        <Button  >
      Documents
        </Button>
        <Button  >
      Events
        </Button>
        <Button  >
      Favorites
        </Button>
        <Button  >
      Friends
        </Button>
        <Button  >
      Mischiefs
        </Button>
        <Button  >
      School
        </Button>
        <Button  >
      BookMarks
        </Button>
        <Button  >
      Reminders
        </Button>
        <Button  >
      More
        </Button>
        </ButtonGroup>
    </Flex>
	   </ExpanderItem>
	)
}

function DisplayChildren({children, getConversations}) {
if (children.length == 0) {
    return <div/>
}
let expanders=[];
for (let i = 0; i < children.length; i++)  {
    expanders.push(<ExpanderItemChild child={children[i].name} item={children[i]} index={i} getConversations={getConversations}/>)
}
         return <div className="conversation2" id="main">
<Expander type="single" isCollapsible={true}>{expanders}</Expander>
</div>
}

function createNewPost() {
    setChildId(user.attributes.sub);
    setExpandedConversation("")
    setConversationTimestamp("")
    setActiveComponent("addconversation");
}

function showChildrenInfo(children, getConversations) {
      return (<div class="overall">
      <div class="stickytop">
      <View className="sideMenu" id="sideMenu" padding="medium">
        <ul>
        <li>
        <Flex justifyContent="space-between">
        <Text> Account info </Text>
        <Button size="small" onClick={() => closeMenu()}><MdOutlineClose/></Button>
        </Flex>
        </li>
        <li>
        <div style={{textAlign:"left"}}>
        <Button size="small" onClick={() => populateConnections(false)}><IoMdContacts/>&nbsp;Contacts</Button>
        </div>
        </li>
        <li>
        <div style={{textAlign:"left"}}>
        <Button  size="small" onClick={() => setActiveComponent("addchild")}><IoMdContacts/>&nbsp;Add Child</Button>
        </div>
        </li>
        <li>
        <div style={{textAlign:"left"}}>
        <Button size="small" onClick={signOut}>Sign Out</Button>
        </div>
        </li>
        <li>
            <View>
            <Text style={{fontSize:"1rem"}}> {user.attributes.email} </Text>
            </View>
        </li>
        </ul>
      </View>
      <Flex padding="small" justifyContent="space-between">
        <Flex>
        <Button size="small" onClick={openMenu}><MdViewHeadline/></Button>
        <Button size="small" onClick={createNewPost}><TfiPencilAlt/>&nbsp;New Post</Button>
        </Flex>
        <Flex>
        <Button size="small" onClick={refresh}><MdRefresh/></Button>
        <NotificationComponent userid={user.attributes.sub}/>
        </Flex>
      </Flex>
      </div>
      <div class="remaining">
	<DisplayTabs children={children} getConversations={getConversations}/>
	</div>
        </div>)
}

function refresh() {
  document.location.reload()
}

function DisplayTabs({children, getConversations}) {
    return (
	<Tabs currentIndex={tabIndex} onChange={(i: number) => setTabIndex(i)} justifyContent="center" spacing="equal"> 
	   <TabItem title="Children">
	   <DisplayChildren children={children} getConversations={getConversations}/>
	   </TabItem>
	   <TabItem title="My Posts">
	    <MyPosts/>
	   </TabItem>
	   <TabItem title="Shared Posts">
	   <SharedPostsMemo/>
	   </TabItem>
        </Tabs>
    )
}

function MyPosts() {
    return <Conversation childid={user.attributes.sub} jsonarray={myPosts}></Conversation>
}

function formatDate(date) {
    let year = date.getFullYear()
    var month = date.getMonth() + 1
    month = "" + month
    month = month.padStart(2, "0")
    var day = date.getDate()
    day = "" + day
    day = day.padStart(2, "0")
    var hour = date.getHours()
    hour = "" + hour
    hour = hour.padStart(2, "0")
    var minute = date.getMinutes()
    minute = "" + minute
    minute = minute.padStart(2, "0")
    var second = date.getSeconds()
    second = "" + second
    second = second.padStart(2, "0")
    return year + "-" + month + "-" + day + " " + hour + ":" + minute 
}

function formatDateFromYYYY(timestamp) {
    return timestamp.replaceAll("/", "-").replaceAll("T", " ").slice(0, 16)
}

function timestampToDate(timestamp) {
   if (timestamp.length == 0) { return "" }
   if (timestamp.startsWith("20")) {
      return formatDateFromYYYY(timestamp)
   }
   let date = new Date(parseInt(timestamp))
   //return date.toDateString()
   return formatDate(date)
}

function toConversationObject(item) {
    let obj =  {
	    conversation: item.conversation? item.conversation.L: [],
        datestr: timestampToDate(item.timestamp.S),
        timestamp: item.timestamp.S,
        cuserid: item.cuserid? item.cuserid.S: "",
        ccognitoid: item.ccognitoid? item.ccognitoid.S: "",
        childid: item.childid? item.childid.S: "",
	liked: item.liked? (item.liked.S == "true"? true : false) : false,
	is_shared: item.is_shared? (item.is_shared.S == "true"? true: false) : false
    }
    return obj
}

function getChildConversations(childid) {
const path = '/conversations/' + childid
API.get(apiName, path, myInit)
  .then((response) => {
      let result = response.map(item => toConversationObject(item));
    setChildId(childid)
    setConversations(result)
    setActiveComponent("showconversations");
    populateFolders(childid)
  })
  .catch((error) => {
    console.log(error.response);
  });
}

function populateFolders(childid) {
   const path = '/folders/' + childid
   API.get(apiName, path, myInit)
   .then((response) => {
      let result = response.map(item => ({ foldername: item.foldername.S, folderid: item.folderid.S}))
    let unsortedFolders = result
    let sortedFolders = unsortedFolders.sort((a, b) => a.foldername < b.foldername)
    setFolders(sortedFolders)
  })
  .catch((error) => {
    console.log(error.response);
  });
}

function populateConnections(noTransition) {
   const path = '/connections/' + user.attributes.sub
   API.get(apiName, path, myInit)
   .then((response) => {
      let result = response.map(item => ({ cemail: item.cemail.S, cuserid: (item.cuserid? item.cuserid.S: ""), cstatus: item.cstatus.S, name: (item.name? item.name.S : ""), hmessage: (item.hmessage? item.hmessage.S : "")}))
    setConnections(result)
    if (!noTransition) {
        moveToShowConnections()
    }
  })
  .catch((error) => {
    console.log(error.response);
  });
}


function moveToShowConnections() {
    setActiveComponent("showconnections")
}

function sendConnectionRequest(hmessage) {
if (hmessage == "") {
    return
}
const path = '/connections/' + user.attributes.sub
const init = {
	body: { name: connection.name, cemail: connection.cemail, semail: user.attributes.email, hmessage: hmessage, cstatus: connection.cstatus},
    headers: {}
}
API.post(apiName, path, init)
  .then((response) => {
    populateConnections(false)
  })
  .catch((error) => {
    console.log(error.response);
  });
  console.log("Sent request to " + connection.cemail + "!")
}

function SendConnectionRequest() {
    return <ConnectionRequest sender={sendConnectionRequest} canceler={() => setActiveComponent("showconnections")}/>
}

function acceptConnectionRequest(name) {
if (name == "") {
	return
}
const path = '/connections/' + user.attributes.sub
const init = {
	body: { name: name, cemail: connection.cemail, semail: user.attributes.email, cstatus: connection.cstatus},
    headers: {}
}
API.post(apiName, path, init)
  .then((response) => {
    populateConnections(false)
  })
  .catch((error) => {
    console.log(error.response);
  });
  console.log("Accept request from " + connection.cemail + "!")
}

function HandleConnectionRequest() {
    return <AcceptConnectionRequest acceptor={acceptConnectionRequest} canceler={() => setActiveComponent("showconnections")}/>
}

function onClickConnectionRequest(item) {
    setConnection(item)
    setActiveComponent("sendconnrequest")
}

function onClickConnectionRequestReceived(item) {
    setConnection(item)
    setActiveComponent("acceptconnrequest")
}

function ShowConnectionActionButton({item}) {
   if (item.cstatus === "Not Connected") {
       return <Button size="small" onClick={() => onClickConnectionRequest(item)}> Send Connection Request </Button>
   }
   if (item.cstatus === "Connection Request Received") {
      if (item.hmessage === "") {
       return <Button size="small" onClick={() => onClickConnectionRequestReceived(item)}> Accept Connection Request </Button>
      }
       return (<View>
		   <Flex direction="column">
                   <Card  variation="elevated" borderRadius="medium">
		   <Text>{item.hmessage} </Text>
		   </Card>
		   <Button size="small" onClick={() => onClickConnectionRequestReceived(item)}> Accept Connection Request </Button>
		   </Flex>
	      </View>)
   }
}

function ShowConnections() {
      return (<div>
         <View padding="medium"><Collection
  items={connections}
  type="list"
  direction="column"
  gap="20px"
  wrap="nowrap"
>
  {(item, index) => (
    <Card
      key={index}
      borderRadius="medium"
      variation="outlined"
    >
    <Flex justifyContent="space-between">
    <Text lineHeight={3}> {item.name} </Text> 
    <Text lineHeight={3}> {item.cstatus} </Text> 
    </Flex>
    <Flex>
    <Text lineHeight={3}> {item.cemail} </Text> 
    </Flex>
    <Flex>
       <ShowConnectionActionButton item={item}/>
    </Flex>
    </Card>
  )}
</Collection>
</View>
<Flex></Flex>
    <Card
      borderRadius="medium"
    >
        <Flex>
        <Button size="small" onClick={() => setActiveComponent("addconnection")}>
      Add Contact
        </Button>
        <Button size="small" onClick={() => cancel()}>
      Back
        </Button>
        </Flex>
    </Card>
        </div>)
}

function ShowChildrenList() {
    return showChildrenInfo(children, getChildConversations);
}

function ShowChildConversations() {
    linkMap.clear()
    return (
	    <div class="overall">
	    <AddStickyBackHeader action={() => setActiveComponent("childinfo")}/>
	    <div class="remaining">
	    <Conversation childid={childid} jsonarray={conversations}></Conversation>
	    </div>
	    </div>
    )
}

function Home() {
const path = '/children'
API.get(apiName, path, myInit)
  .then((response) => {
    let result = response.map( item => ({ name: item.name.S, childid: item.childid.S}));
    // result.push({name: "Me", childid: user.attributes.sub})
    result.sort(function (a, b) { if (a.name < b.name) {return -1;} if (a.name > b.name) {return 1} return 0; })
    setChildren(result)
      
    populateConnections(true)
    populateSharedPosts()
    if (result.length === 0) {
        setTabIndex(2)
    }
    setActiveComponent("childinfo")
    populateMyPosts()
  })
  .catch((error) => {
    console.log(error.response);
  });
}

function convertConversation(convArray) {
    let result = [] 
    for (let i = 0; i < convArray.length; i+= 2) {
        let speaker = convArray[i]
        result.push(speaker)
        let text = convArray[i+1]
        if (speaker.startsWith('aud/')) {
            if (text.startsWith("blob")) {
	        if (linkMap.has(text)) {
                    result.push(linkMap.get(text))
                }
                else {
                    throw new Error("Failed to save audio")
                }
            }
		else {
		    result.push(text)
		}
	}
        else if (speaker.startsWith('vid/')) {
            if (typeof text === "object") {
                let url = text.url
                if (url.startsWith("blob")) {
	            if (linkMap.has(url)) {
                        result.push(linkMap.get(url))
                    }
                    else {
                        throw new Error("Failed to save video")
                    }
                } else {
	            result.push(text)
		}
	    }
	    else {
	        result.push(text)
	    }
    }
    else if (speaker.startsWith('img/')) {
        let photos = text
        let temp = []
        for (let i = 0; i < photos.length; i+=1) {
            if (photos[i].url) {
                let keyUrl = photos[i].url
                if (linkMap.has(keyUrl)) {
                    temp.push(linkMap.get(keyUrl))
                }
                else {
                    throw new Error("Failed to save image")
                }
             } else {
                 temp.push(photos[i])
	     }
        }
        result.push(temp)
    }
        else {
            result.push(text)
        }
    }
    return result
}

function getDateString() {
    if (conversationTimestamp) {
        return conversationTimestamp
    }
    let date = new Date()
    return date.toISOString().replaceAll("-", "/")
}

function ddbAttributeForConversationItem(item) {
    if (Array.isArray(item)) {
       let result = item.map((elem) => { return {"S" : elem}})
       return {"L": result}
    }
    if (typeof item === 'object') {
       let result = {}
       let entries = Object.entries(item)
       entries.forEach((entry) => {
           const [key, value] = entry;
           result[key] = { "S": value }
       });
       return { "M" : result }
    }
    return {"S": item}
}

function addConversation(childid, conversation, myPostsIndex) {
    if (conversation.length == 0) {
       return;
    }
    let titleSet = false
    for (let i = 0; i < conversation.length; i +=2) {
        let speaker = conversation[i]
        if (speaker.startsWith("title/")) {
           titleSet = true
    }
    }
    if (!titleSet) {
        window.alert("Please add a title for this post.")
        return
    }
    let targetConversation = convertConversation(conversation)
const path = '/conversations/' + childid
const bodyContent = { timestamp: getDateString(), conversation: targetConversation }
const init = {
    body: bodyContent,
    headers: {}
}
API.post(apiName, path, init)
  .then((response) => {
    let conversation2 = targetConversation.map((elem) => ddbAttributeForConversationItem(elem));
    let obj = { timestamp: { "S": bodyContent.timestamp }, conversation: { "L" : conversation2 } }
    console.log("MypostsIndex = ")
    console.log(myPostsIndex)
    if (typeof myPostsIndex === 'undefined') {
        myPosts.unshift(toConversationObject(obj))
    }
    else {
        myPosts[myPostsIndex] = toConversationObject(obj)
    }
    setActiveComponent("childinfo")
    if (typeof myPostsIndex !== 'undefined') {
        backToMainView(conversationTimestamp)
    }
  })
  .catch((error) => {
    console.log(error);
    console.log(error.response);
  });
}

function addChild(childName, date) {
    if (childName=="") {
       return;
    }
const path = '/children'
const init = {
    body: { name: childName, dob: date },
    headers: {}
}
API.post(apiName, path, init)
  .then((response) => {
    setActiveComponent("home")
  })
  .catch((error) => {
    console.log(error.response);
  });
}

function cancel(){
    setActiveComponent("childinfo")
}

function AddChild() {
    return <AddChildDetails adder={addChild} canceler={cancel}></AddChildDetails>
}

function addConnection(name, email) {
    if (name=="") {
       return;
    }
    if (email=="") {
       return;
    }
const path = '/connections/' + user.attributes.sub
const init = {
	body: { name: name, cemail: email, semail:user.attributes.email},
    headers: {}
}
API.post(apiName, path, init)
  .then((response) => {
    populateConnections(false)
  })
  .catch((error) => {
    console.log(error.response);
  });
}
function AddConnection() {
    return <AddConnectionDetails adder={addConnection} canceler={() => setActiveComponent("showconnections")}/>
}

function getSpeakers() {
    let speakers = children.map(x => x.name)
    speakers.push("Me");
    return speakers
}

let linkMap = new Map();

function getDatePrefix() {
   var dateObj = new Date();
   var month = dateObj.getUTCMonth() + 1; //months from 1-12
   var day = dateObj.getUTCDate();
   var year = dateObj.getUTCFullYear();
   return year + "/" + month + "/" + day + "/";
}

function AddChildConversation() {
    let speakers = getSpeakers()
    let uuid = getDatePrefix() + uuidv4()
    return <AddConversation cid={uuid} linkMap={linkMap} childid={user.attributes.sub} adder={addConversation} speakers={speakers} canceler={cancel} initial={[]}></AddConversation>
}

function ShowMore() {
    return <More childid={childid} canceler={cancel}></More>
}

function ShowExpandedConversation() {
    return (<div class="overall">
	    <AddStickyBackHeader action={() => backToMainView(conversationTimestamp)}/>
	    <div class="remaining">
            <ExpandConversation setImageDivId={setImageDivId} conversation={expandedConversation} canceler={backToMainView} timestamp={conversationTimestamp} isProtected={protectedVal} cognitoId={cognitoId}></ExpandConversation>
	    </div>
	    </div>)
}

function getConversationFromExpanded() {
    return expandedConversation.map( obj => {
        if (obj.S) {return  obj.S} 
        else if (obj.L) { return obj.L.map(i => i.S)}
        else if (obj.M) { return  Object.fromEntries(Object.entries(obj.M).map(([k, v]) => [k, v.S]))}
    })
}

function ShowEditConversation() {
    let speakers = getSpeakers()
    let uuid = getDatePrefix() + uuidv4()
    let initial = getConversationFromExpanded()
    return <AddConversation cid={uuid} linkMap={linkMap} childid={user.attributes.sub} adder={addConversation} speakers={speakers} canceler={backToMainView} initial={initial} myPostsIndex={myPostsIndex} ></AddConversation>
}

function moveOrCopy(folderList, timestamp) {
    if (folderList.length < 1) {
        return
    }
    let folderid = folderList[0]
const path = '/folderposts/' + folderid
const init = {
    body: { timestamp: getDateString(),
	    conversation: expandedConversation },
    headers: {}
}
API.post(apiName, path, init)
  .then((response) => {
    backToMainView(timestamp)
  })
  .catch((error) => {
    console.log(error.response);
  });
}

function ShowMoveOrCopyOptions() {
    return <MoveOrCopy folders={folders} adder={moveOrCopy} canceler={backToMainView} timestamp={conversationTimestamp}/>
}

function callSharedPostProcessor() {
const path = '/sharedpostsprocessor/' + user.attributes.sub
const init = {
    body: { timestamp: conversationTimestamp,
	    sub: user.attributes.sub,
	    childid: user.attributes.sub,
	    conversation: expandedConversation },
    headers: {}
}
API.post(apiName, path, init)
  .then((response) => {
    console.log("Response from shared post processor!")
  })
  .catch((error) => {
    console.log(error.response);
  });
}

function updateShares(recipients, timestamp, childid) {
const path = '/shares/' + user.attributes.sub
const init = {
    body: { timestamp: timestamp,
	    sub: user.attributes.sub,
	    childid: childid,
	    recipients: recipients },
    headers: {}
}
API.post(apiName, path, init)
  .then((response) => {
  })
  .catch((error) => {
    console.log(error.response);
  });
}

function share(connectionList, timestamp, childid, myPostsIndex) {
    if (connectionList.length < 1) {
        return
    }
    let recipients = connectionList.map( conn => {  return {"userid" : conn.cuserid, "email" : conn.cemail} } )
    let connectionOne = connectionList[0]
const path = '/sharedposts/' + connectionOne.cuserid
const init = {
    body: { timestamp: timestamp,
	    sub: user.attributes.sub,
	    childid: childid,
	    recipients: recipients,
	    cemail: user.attributes.email,
	    conversation: expandedConversation },
    headers: {}
}
API.post(apiName, path, init)
  .then((response) => {
    updateShares(recipients, timestamp, childid)
    callSharedPostProcessor()
    myPosts[myPostsIndex].is_shared = true;
    backToMainView(timestamp)
  })
  .catch((error) => {
    console.log(error.response);
  });
}

function ShowShareOptions() {
    return <Share connections={connections} share={share} canceler={backToMainView} timestamp={conversationTimestamp} childid={user.attributes.sub} myPostsIndex={myPostsIndex}/>
}

  return (
      <div>
      <View id="fullpage">
      <Button id="fullpagebutton" onClick={() => resetBackground()}><MdOutlineClose/></Button>
      </View>
      <SwitchComponents active={activeComponent}>
      <Home name="home" />
      <ShowChildrenList name="childinfo" />
      <AddChild name="addchild" />
      <AddChildConversation name="addconversation" />
      <ShowMore name="more" />
      <ShowChildConversations name="showconversations" />
      <ShowExpandedConversation name="showexpanded" />
      <ShowEditConversation name="editconversation" />
      <ShowMoveOrCopyOptions name="moveorcopy" />
      <ShowShareOptions name="share" />
      <ShowConnections name="showconnections"/>
      <AddConnection name="addconnection"/>
      <SendConnectionRequest name="sendconnrequest"/>
      <HandleConnectionRequest name="acceptconnrequest"/>
      <SharedPosts name="sharedposts"/>
      <ShowSharesAndLikes name="showlikes"/>
      </SwitchComponents>
      </div>
  );
}

export default withAuthenticator(App);
