import React, {useState} from 'react';
import {
  Button,
  Flex,
  TextField,
  View,
  Card,
} from "@aws-amplify/ui-react";

export default function AcceptConnectionRequest({acceptor, canceler}) {
	const [message, setMessage] = useState("")
	return (<View> 
		<Card>
		<TextField padding="medium" size="default"
  placeholder=""
  label="Set Contact Name"
	onChange={(e) => {setMessage(e.currentTarget.value)}}
/>
        <Flex padding="medium">
        <Button  onClick={() => acceptor(message)} >
	 Save
        </Button>
        <Button  onClick={() => canceler()} >
	  Cancel
        </Button>
        </Flex>
		</Card>
		</View>)
}
