import { v4 as uuidv4 } from 'uuid';
import React, {useState} from 'react';
import { AiFillAudio, AiFillCamera } from "react-icons/ai";
import { BsChatText } from "react-icons/bs";
import { SlNote } from "react-icons/sl";
import { FaVideo } from "react-icons/fa";
import { MdTitle } from "react-icons/md";
import { BiLink } from "react-icons/bi";
import {
  Button,
  ButtonGroup,
  ToggleButton,
  Flex,
  Heading,
  View,
  Loader,
  Placeholder,
  Image,
	RadioGroupField,
	Radio,
	TextAreaField,
	SelectField,
	Divider,
	Badge,
	Text,
  TextField,
  Card,
} from "@aws-amplify/ui-react";
import {Storage} from 'aws-amplify';
import SwitchComponents from './SwitchComponents';
import AddTitle from './AddTitleStep';
import AddTextNote from './AddTextNoteStep';
import AddVoiceNote from './AddVoiceNoteStep';
import {AddPhoto, handlePhotoPick} from './AddPhotoStep';
import AddSpeakerText from './AddSpeakerTextStep';
import AddLink from './AddLink';

function getTitleFromConversations(conversations) {
    let result = ''
    for (let i=0; i < conversations.length; i++) {
        if (conversations[i] === "title/") {
            result = conversations[i+1]
	}
    }
    return result
}

export default function AddConversationStep({cid, uploadPercentSetter, linkMap, initial, setter, speakers, setPreview}) {
	const [activeComponent, setActive] = useState("home")
	const [uploadedPhotoCount, setUploadedPhotoCount] = useState(0)
	const [totalPhotoCount, setTotalPhotoCount] = useState(0)
	const [uploadedVideoSize, setUploadedVideoSize] = useState(0)
	const [totalVideoSize, setTotalVideoSize] = useState(0)

   let initialAudio = {
    audioDetails: {
        url: null,
        blob: null,
        chunks: null,
        duration: {
          h: 0,
          m: 0,
          s: 0
        }
      }
   }
   const [audioRecording, setAudioRecording] = useState(initialAudio)

   let initialPhoto = {
       url: "",
       file: ""
   }
   const [photos, setPhotos] = useState([])

   let initialVideo = {
       url: "",
       file: ""
   }
   const [video, setVideo] = useState(initialVideo)

async function addConversationStep(speaker, text, transitiontoHome) {
    console.log("calling add conversation")
    addConversationStep(speaker, text, transitiontoHome, "")
}

async function addPhotosStep(pics) {
    setTotalPhotoCount(pics.length)
    setActiveComponent("photoloader")
    let buffer = initial.map(x => x)
    for (let i = 0; i < pics.length; i+= 1) {
        let imgUUID = uuidv4()
        let fileName = cid + "/img/" + imgUUID + "/" + i
        let url = pics[i].url
        console.log("storing in s3: " + url)
        await Storage.vault.put(fileName, pics[i].file).then((result) => linkMap.set(url, result.key))
        setUploadedPhotoCount(i + 1)
    buffer.push("img/")
    buffer.push([pics[i]])
    setter(buffer)
    }
    setHome()
}

function updateVideoUploadState(progress) {
    setTotalVideoSize(progress.total)
    setUploadedVideoSize(progress.loaded)
}

async function addVideoStep(video) {
    let fileUuid = uuidv4()
    let fileName = cid + "/vid/" + fileUuid
    let rawVideoFileName = fileName + ".rawvideo"
    let url = video.url
    let file = video.file
    let buffer = initial.map(x => x)
    buffer.push("vid/")
    let videoobj = { "url": url, "width" : "" + video.width, "height": "" + video.height }
    buffer.push(videoobj)
    setter(buffer)
    let linkmapobj = { "url" : fileName, "width": "" + video.width, "height": "" + video.height }
    console.log("linkmapobj")
    console.log(linkmapobj)
    Storage.vault.put(rawVideoFileName, file, {
        contentType: "video/mp4",
        progressCallback(progress) {
            updateVideoUploadState(progress)
            setActiveComponent("videoloader")
        }
    }).then((result) => { 
        linkMap.set(url, linkmapobj)
        setHome()
    })
}

async function addLinkStep(text) {
    if (text === "") {window.alert("URL cannot be empty"); return}
    let buffer = initial.map(x => x)
    buffer.push("link/")
    buffer.push(text)
    setter(buffer)
    setHome()
}

async function addTitleStep(titleInput) {
    if (titleInput === "") {window.alert("Title cannot be empty"); return}
    let buffer = initial.map(x => x)
    buffer.push("title/")
    buffer.push(titleInput)
    setter(buffer)
    setHome()
}

async function addConversationStep(speaker, text, transitiontoHome) {
        if (text == "") {
		return 
	}
	if (speaker.startsWith("aud/")) {
	    let audio = audioRecording.audioDetails
	    if (audio.duration.h == 0 && audio.duration.m == 0 && audio.duration.s == 0) { return }
	}
	let buffer = initial.map(x => x)
	buffer.push(speaker)
	buffer.push(text)
	setter(buffer)
	if (speaker.startsWith("aud/")) {
	    let fileUuid = uuidv4()
	    let fileName = cid + "/aud/" + fileUuid
	    let url = audioRecording.audioDetails.url
	    let file = audioRecording.audioDetails.blob
	    Storage.vault.put(fileName, file, {contentType: "audio/mpeg"}).then((result) => {
	        linkMap.set(url, fileName); 
	        if (transitiontoHome) {
		   setHome()
	        }
	    })
	}
	else {
	        if (transitiontoHome) {
		   setHome()
	        }
	}
}


function reset() {
    setAudioRecording(initialAudio)
    setVideo(initialVideo)
    setUploadedPhotoCount(0)
    setTotalPhotoCount(0)
}

function setHome() {
    reset()
    setActive("home")
    setPreview(true)
}

function setActiveComponent(name) {
    // setPreview(false)
    setActive(name)
}

async function handlePhotos(e) {
setActiveComponent("uploadplaceholder")
let pics = await handlePhotoPick(e)
//setPhotos(pics)
// console.log(photos) // do not remove this
await addPhotosStep(pics)
}

function handleVideoPick(e) {
	let fileName = e.target.files[0]
	const URL = window.URL || window.webkitURL;
	let url = URL.createObjectURL(fileName);
	let vid = document.getElementById("video")
	// vid.src = url
	//vid.load()
	console.log("Width and Height: " + vid.videoWidth +  " " + vid.videoHeight)
	return {file: fileName, url: url, width: vid.videoWidth, height: vid.videoHeight}
    }

async function handleVideos(e) {
    setActiveComponent("uploadplaceholder")
let videos = await handleVideoPick(e)
//setPhotos(pics)
// console.log(photos) // do not remove this
await addVideoStep(videos)
}

function PhotoLoader() {
       console.log("Moving to PhotoLoader!")
       return (
	<Flex direction="column" paddingTop="xs" class="uploadinfo" justifyContent="center"> <Loader percentage={Math.floor(uploadedPhotoCount * 100 / totalPhotoCount)} variation="linear"/>
	<Text> Uploaded {uploadedPhotoCount} of {totalPhotoCount} photos</Text>
	</Flex>)
}

function VideoLoader() {
       console.log("Moving to VideoLoader!")
       let percent = Math.floor(uploadedVideoSize * 100 / totalVideoSize) 
       var total = totalVideoSize
       let oneMB = 1024 * 1024
       let oneKB = 1024
       var unit = "MB"
       if (total < oneMB) {
       unit = "KB"
       total = Math.floor(total / oneKB)
       }
       else {
        total = Math.floor(total / oneMB)
       }
       return (
	<Flex paddingTop="xs" justifyContent="center" class="uploadinfo"> <Loader percentage={percent} variation="linear" isDeterminate isPercentageTextHidden/>
	<Text> Uploaded {percent}% of {total}{unit}</Text>
	</Flex>)
}

function Home() {
   return <View>
   <Flex justifyContent="flex-start" gap="0.4rem">
   <Button size="small"  onClick={() => setActiveComponent("addtitle")} title="Add Title"><Text style={{fontSize:"0.8rem"}} > Title</Text></Button>
   <Button size="small"  onClick={() => setActiveComponent("addtextnote")} title="Add Text"><SlNote/></Button>
   <Button size="small"  onClick={() => setActiveComponent("addspeakertext")} title="Add Chat"><BsChatText/></Button>
   <Button size="small"  onClick={() => setActiveComponent("addvoicenote")} title="Add Audio"><AiFillAudio/></Button>
   <label class="imagepick"> 
		<input type="file" onChange={handlePhotos} accept="image/*" multiple/>
                <Card borderRadius="small" variation="outlined" style={{fontSize: "1rem"}} padding="xs">
                <AiFillCamera/>
                </Card>
		</label>
   <label class="videopick"> 
		<input type="file" onChange={handleVideos} accept="video/*" multiple/>
                <Card borderRadius="small" variation="outlined" style={{fontSize: "1rem"}} padding="xs">
                <FaVideo/>
                </Card>
		</label>
   <Button size="small"  onClick={() => setActiveComponent("addlink")} title="Add Link"><BiLink/></Button>
   </Flex>
   </View>
}

function UploadPlaceHolder() {
   return <Flex class="uploadinfo" justifyContent="center" direction="column" ><Placeholder/><Text padding="xs">Waiting for upload to start...</Text></Flex>
}

	return (<div> 
		<View paddingTop="xs">
		<SwitchComponents active={activeComponent}>
		<Home name="home"/>
		<PhotoLoader name="photoloader"/>
		<UploadPlaceHolder name="uploadplaceholder"/>
		<VideoLoader name="videoloader"/>
		<AddTitle name="addtitle" addTitleStep={addTitleStep} initialTitle={getTitleFromConversations(initial)} cancel={setHome}/>
		<AddTextNote name="addtextnote" addConversationStep={addConversationStep} cancel={setHome}/>
		<AddVoiceNote name="addvoicenote" voiceNote={audioRecording} setVoiceNote={setAudioRecording} addConversationStep={addConversationStep} cancel={setHome}/>
		<AddSpeakerText name="addspeakertext" speakers={speakers} addConversation={addConversationStep} cancel={setHome}/>
		<AddLink name="addlink" addLinkStep={addLinkStep} cancel={setHome}/>
		</SwitchComponents>

		</View>
		</div>)
}
