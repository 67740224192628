import React, {useState} from 'react';
import {
  Heading,
  Text,
  Badge,
  Card,
  View,
  Radio,
  RadioGroupField,
  Flex,
  Button,
  TextAreaField
} from "@aws-amplify/ui-react";


export default function AddSpeakerText({speakers, addConversation, cancel}) {
	const [speaker, setSpeaker] = useState("Me")
	const [text, setText] = useState("")
	const [conversations, setConversations] = useState([])

function undo() {
   let buffer = conversations.map(x => x)
   buffer.pop()
   buffer.pop()
   setConversations(buffer)
}
function AddButtons(){
if (conversations.length == 0) { return <div> </div> }
return (
        <Flex padding="xs">
        <Button  onClick={() => undo()}>
	  Undo
        </Button>
        </Flex>)
}
function AddSpeakers() {
    return <RadioGroupField label="Speaker"  name="Speaker" value={speaker} onChange={(e) => setSpeaker(e.currentTarget.value)}>
		{speakers.map((sp) => <Radio key={sp} value={sp}>{sp}</Radio>)}
           <Radio value="Other">Other</Radio>
    </RadioGroupField>
}
function AddSpeakerTextWithBadge({speaker, text}) {
    return <View padding="xs">
	<ProcessBadge value={speaker} />
	<ProcessText  value={text} />
	</View>
}
function ProcessConversationSteps() {
    const components = []
    if (conversations.length == 0) {
        return <Text padding="xs">Conversation will appear here!</Text>
    }
    
    for (let i = 0; i < conversations.length; i+= 2) {
        let speaker = conversations[i]
        let text = conversations[i+1]
        components.push(<AddSpeakerTextWithBadge speaker={speaker} text={text} />);
    }
    return (<div> {components} </div>)
}
function Second({childid, conversations}) {
if (conversations.length == 0) {
return <div></div>
}
return(
    <div>
    <View>
    <Card
      key="1"
      borderRadius="medium"
      variation="outlined"
    >
        <ProcessConversationSteps />
    </Card>
    </View>
        <br/>
        <Flex>
        <Button  onClick={() => done()}>
	  Done
        </Button>
        </Flex>
	  </div>)
}

function getPlaceHolder() {
	if(speaker == 'Me') { return 'I said this' }
	else if(speaker == 'Other') { return 'Other person said this' }
 return speaker + " said this"
}
function ProcessBadge({value}) {
	return  <Badge backgroundColor="teal.10" padding="xs">{value}</Badge>
}
function ProcessText({value}) {
	return <Text padding="xs" as="p" style={{whiteSpace:"pre-line"}}> {value} </Text>
}

function addSpeakerText(speaker, text) {
	if (text == "") return
	let buffer = conversations.map(x => x)
	buffer.push(speaker)
	buffer.push(text)
	setConversations(buffer)
}

function done() {
    addConversation("chat/", conversations, false)
    cancel()
}

    return <View><View>
		<AddSpeakers/>
	<TextAreaField
  placeholder={getPlaceHolder()}
  label=""
       onChange={(e) => setText(e.currentTarget.value)}
               value = {text}
/>
        <br />
        <Flex>
        <Button  onClick={() => {addSpeakerText(speaker, text); setText("")}}>
	  Add
        </Button>
        </Flex>

		</View>
		<br/>
        <Second childid="abc" conversations={conversations}/>
        </View>
}
