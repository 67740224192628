import React, { useRef, useState } from 'react';

import {Flex,View} from "@aws-amplify/ui-react";

export default function DatePicker({setter}) {
  const [date, setDate] = useState('');
  const dateInputRef = useRef(null);

  const handleChange = (e) => {
    setDate(e.target.value);
    setter(e.target.value);
  };

  return (
    <View>
      <span>Date of Birth</span>
      <input
        type="date"
        onChange={handleChange}
        ref={dateInputRef}
      />
	</View>
  );
};
