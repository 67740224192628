import {useState} from 'react';
import {
  View,
  Flex,
  Button,
  Text,
  TextField,
} from "@aws-amplify/ui-react";

export default function AddTitle({addTitleStep, initialTitle, cancel}) {
		const [title, setTitle] = useState(initialTitle)
		return <View id="titleDiv"><TextField label="Title" placeholder="title" value={title} onChange={(e) => setTitle(e.target.value)}/>
        <br/>
        <Flex>
        <Button  onClick={() => {addTitleStep(title)}} size="small">
	  Add
        </Button>
        <Button  onClick={() => cancel()} size="small">
	  Cancel
        </Button>
        </Flex>
        </View>
}
