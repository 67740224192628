import { useState, useEffect } from 'react';
import {Storage} from 'aws-amplify';
import {
  Button,
  Flex,
  Text,
  TextField,
  View,
  Card,
} from "@aws-amplify/ui-react";

export default function AddAudioFromS3({filename}){
     const [signedUrl, setSignedUrl] = useState("")
     useEffect(() => {
         async function getSignedUrl() {
             const url = await Storage.vault.get(filename, {expires: 84600})
             setSignedUrl(url)
     }
     getSignedUrl()
     }, [])
     return <View padding="xs">
            <audio controls>
              <source src={signedUrl} type='audio/ogg' />
              <source src={signedUrl} type='audio/mpeg' />
              <source src={signedUrl} />
            </audio>
            </View>
}
