import React, {useState, useEffect} from 'react';
import { SlNote } from "react-icons/sl";
import {
  Button,
  Grid,
  Flex,
  Heading,
  Image,
	Divider,
	Badge,
	Text,
  TextField,
	View,
  Card,
} from "@aws-amplify/ui-react";
import {Storage} from 'aws-amplify';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel'

import AddConversationStep from './AddConversationStep.js'
import AddVideoFromS3 from './AddVideoFromS3.js'

export default function ExpandConversation({setImageDivId, conversation, canceler, timestamp, isProtected, cognitoId}) {

function getBadgeColor(badgeText) {
    if (badgeText == "@child") return 'blue.10'
    if (badgeText == '@you') return 'green.10'
    if (badgeText == '@comment') return 'red.10'
    else return 'teal.20'
}

function Second() {
return(
    <div class="conversation2" id="conversation2">
    <View padding="xs">
        <ProcessConversationSteps />
    </View>
	  </div>)
}

function ProcessConversationSteps() {
    let itemConversations = conversation;
    const components = []
    let title=""
    for (let i = 0; i < itemConversations.length; i+= 2) {
        let speaker = itemConversations[i].S
        let text = itemConversations[i+1].S
        if (speaker == "title/") {
            title = text
	}
    }
    if (title.length > 0) {
        components.push(<Heading level={3} padding="xxs">{title}</Heading>)
    }
    for (let i = 0; i < itemConversations.length; i+= 2) {
        let speaker = itemConversations[i].S
        let text = itemConversations[i+1].S
        var videoM = itemConversations[i+1].M
        if (!videoM) { videoM = text }
        if (speaker.startsWith('title/')) {
            continue;
	}
        if (speaker.startsWith("vid/")) {
            components.push(
                <AddVideoFromS3 filename={videoM} isProtected={isProtected} cognitoId={cognitoId}/>
	    )
	}
        else if (speaker.startsWith('note/')) {
            components.push(<AddTextNote src={text}/>)
	}
	else if (speaker.startsWith("aud/")) {
            components.push(
                <AddAudioFromS3 filename={text}/>
	    )
	} else if (speaker.startsWith("img/")) {
            let array = itemConversations[i+1].L
            let photos = []
            if (array) {
               photos = array.map(x => x.S)
	    }
       	    else {
			photos = [text]
		}
            for (let photoindex=0; photoindex < photos.length; photoindex++) {
            components.push(<AddImages src={[photos[photoindex]]} isProtected={isProtected} cognitoId={cognitoId}/>)
	    }
	}
        else if (speaker.startsWith('chat/')) {
            let array = itemConversations[i+1].L
            let chat = []
            if (array) {
               chat = array.map(x => x.S)
	    }
            components.push(<AddChat chat={chat}/>)
	} 
	else if (speaker.startsWith('link/')) {
	           console.log(text)
		    if (isYouTubeLink(text)) {
	           console.log(text + " is a youtube link")
		    let link = processYoutubeLink(text)   
		    components.push(<Carousel showIndicators={false}><iframe src={link}  frameborder="0" allowFullScreen style={{overflow:"inherit", WebkitBorderRadius:"3%"}}></iframe></Carousel>)
		    }
		    else {
		       console.log("it is not a youtube link")
		       components.push(<View padding="xs"><a href='#'>{text}</a></View>)
		    }
	} else {
        components.push(
        <div>
        <ProcessBadge value={speaker} />
        <ProcessText speaker={speaker} value={text} />
        </div>);
	}
    }
    return (<div class="content"> {components} </div>)
}

function isYouTubeLink(inputUrl) {
    return typeof inputUrl !== 'undefined' && (inputUrl.includes("youtu.be/") || inputUrl.includes("youtube.com/"))
}
function processYoutubeLink(inputUrl) {
    var videoId = extractVideoId(inputUrl)
    return "https://www.youtube.com/embed/" + videoId
}

// Function to extract the YouTube video ID from the URL
function extractVideoId(url) {
  var videoId = '';

  // Patterns to match different YouTube URL formats
  var patterns = [
    /youtu\.be\/([^\/]+)/,
    /youtube\.com\/watch\?v=([^\/]+)/,
    /youtube\.com\/embed\/([^\/]+)/,
    /youtube\.com\/v\/([^\/]+)/,
    /youtube\.com\/user\/[^\/]+\/([^\/]+)/
  ];

  // Check each pattern and extract the video ID if found
  for (var i = 0; i < patterns.length; i++) {
    var match = url.match(patterns[i]);
    if (match && match[1]) {
      videoId = match[1];
      break;
    }
  }

  return videoId;
}


function ProcessBadge({value}) {
	return  <Badge backgroundColor={getBadgeColor(value)} padding="xs">{value}</Badge>
}
function ProcessText({speaker, value}) {
	return <Text padding="xs" as="p" style={{whiteSpace:"pre-line"}}> {value} </Text>
}

function AddImage({src}){
   return <View class="carousel-item" padding="xxxs"><Image src={src} style={{WebkitBorderRadius: "3%"}}/></View>
}

function expandImage(signedUrl, filename) {
    const fullPage = document.getElementById('fullpage');
    fullPage.style.backgroundImage = "url('" + signedUrl +"')";
    fullPage.style.display = 'block';
    const convo = document.getElementById('conversation2')
    convo.style.display = 'none';
    let imageDivId2 = filename + "expanded";
    setImageDivId(imageDivId2);
}

function AddImages({src, isProtected, cognitoId}) {
            let components = []
            for (let i = 0; i < src.length; i+=1) {
                components.push(<AddImageFromS3 filename={src[i]} index={i} isProtected={isProtected} cognitoId={cognitoId}/>)
	    }
	    if (src.length == 1) {
		    return <View> {components} </View>
	    }
	    return <Carousel showThumbs={false} showIndicators={false} style={{overflow: "hidden"}}>{components}</Carousel>
}
function AddImageFromS3({filename, index}) {
     const [signedUrl, setSignedUrl] = useState("")
     useEffect(() => {
         async function getSignedUrl() {
             var url = ""
	     if (isProtected) {
		 url = await Storage.get(filename, {expires: 84600, level: "protected", identityId: cognitoId})
	     }
	     else {
		 url = await Storage.vault.get(filename, {expires: 84600})
	     }
             setSignedUrl(url)
     }
     getSignedUrl()
     }, [])
     if (index < 1) {
         return <View id={filename + "expanded"} padding="medium" class="carousel-item">
		    <Image class="imgfit" width="auto" height="450px" objectFit="cover" src={signedUrl} onClick={() => expandImage(signedUrl, filename)} style={{WebkitBorderRadius:"3%", overflow:"hidden", pointerEvents: "auto"}} />
		 </View>
     }
     // return <View padding="medium" class="carousel-item"><Image clas="imgfit" src={signedUrl} style={{WebkitBorderRadius:"3%", overflow:"hidden"}} loading="lazy" /></View>
     return <View id={filename + "expanded"} padding="medium" class="carousel-item">
		    <Image class="imgfit" width="auto" height="450px" objectFit="cover" src={signedUrl} onClick={() => expandImage(signedUrl, filename)} style={{WebkitBorderRadius:"3%", overflow:"hidden", pointerEvents: "auto"}} loading="lazy"/>
		 </View>
}

function AddAudioFromS3({filename}){
     const [signedUrl, setSignedUrl] = useState("")
     useEffect(() => {
         async function getSignedUrl() {
             const url = await Storage.vault.get(filename)
             setSignedUrl(url)
	 }
	 getSignedUrl()
     }, [])
     return <View><audio src={signedUrl} controls/></View>
}

function AddTextNote({src}){
   return <View> <Card variation="elevated"><Text as="p" style={{whiteSpace:"pre-line"}}>{src}</Text></Card><br/></View>
}

function AddChat({chat}) {
    let components = []
    for (let i = 0; i < chat.length; i+= 2) {
        components.push(<AddSpeakerText speaker={chat[i]} text={chat[i+1]}/>)
    }
    return (<div> 
	<Grid
  columnGap="0.1rem"
  rowGap="0.1rem"
  templateColumns="5px 1fr"
>
  <Card
    columnStart="1"
    columnEnd="2"
  >
  </Card>
  <Card>
		{components}
		</Card>
</Grid></div>)

}

function AddSpeakerText({speaker, text}) {
    return <View padding="xs">
	<ProcessBadge value={speaker} />
	<ProcessText speaker={speaker} value={text} />
	</View>
}

  return (
	  <Second/>
  )
}
