import React, {useState} from 'react';
import {
  Button,
  Flex,
  TextField,
  View,
  Card,
} from "@aws-amplify/ui-react";

import DatePicker from './DatePicker';

export default function AddChildDetails({adder, canceler}) {
        const [date, setDate] = useState('');
	const [teacher, setTeacher] = useState("")
	const [institute, setInstitute] = useState("")
	return (<View> 
		<Card>
		<TextField padding="medium" size="default"
  placeholder=""
  label="Name of teacher"
	onChange={(e) => {setTeacher(e.currentTarget.value)}}
/>
		<TextField padding="medium" size="default"
  placeholder=""
  label="Name of institute"
	onChange={(e) => {setInstitute(e.currentTarget.value)}}
/>
        <Flex padding="medium">
        <Button  onClick={() => adder(teacher, institute)} >
	 Save
        </Button>
        <Button  onClick={() => canceler()} >
	  Cancel
        </Button>
        </Flex>
		</Card>
		</View>)
}
