import {useState} from 'react';
import {
  View,
  Flex,
  Button,
  TextAreaField,
} from "@aws-amplify/ui-react";

export default function AddTextNote({addConversationStep, cancel}) {
	const [textNote, setTextNote] = useState("")
		return <View><TextAreaField placeholder="add text here" value={textNote} onChange={(e) => setTextNote(e.target.value)}/>
        <br/>
        <Flex>
        <Button  onClick={() => {addConversationStep("note/", textNote, true)}} size="small">
	  Add
        </Button>
        <Button  onClick={() => cancel()} size="small">
	  Cancel
        </Button>
        </Flex>
        </View>
}
