import React, {useState} from 'react';
import {
  Button,
  Flex,
  TextField,
  View,
  Card,
} from "@aws-amplify/ui-react";


export default function AddFavoriteDetails({adder, canceler}) {
	const [attribute, setAttribute] = useState("")
	const [value, setValue] = useState("")
	return (<View> 
		<Card>
		<TextField padding="medium" size="default"
  placeholder=""
  label="Attribute Name (e.g. color, toy, place)"
	onChange={(e) => {setAttribute(e.currentTarget.value)}}
/>
		<TextField padding="medium" size="default"
  placeholder=""
  label="Value"
	onChange={(e) => {setValue(e.currentTarget.value)}}
/>
        <Flex padding="medium">
        <Button  onClick={() => adder(attribute, value)} >
	 Save
        </Button>
        <Button  onClick={() => canceler()} >
	  Cancel
        </Button>
        </Flex>
		</Card>
		</View>)
}
