import { useState, useEffect } from 'react';
import {Storage} from 'aws-amplify';
import {
  Button,
  Flex,
  Text,
  TextField,
  View,
  Image,
  Card,
} from "@aws-amplify/ui-react";
import { Carousel } from 'react-responsive-carousel';

function expandImage(signedUrl, filename, setImageDivId) {
    const fullPage = document.getElementById('fullpage');
    fullPage.style.backgroundImage = "url('" + signedUrl +"')";
    fullPage.style.display = 'block';
    const convo = document.getElementById('conversation2')
    convo.style.display = 'none';
    setImageDivId(filename)
}

function MultipleDivBackgroundImage({signedUrl, filename, setImageDivId}){
return <Carousel id="fullpage2" showThumbs={false} showIndicators={true} style={{overflow: "hidden"}} autoPlay={false} infiniteLoop={false}>
       <div style={{
        'backgroundSize': 'cover',
        backgroundImage: "url('" + signedUrl +"')"
       }}></div>
	</Carousel>
}

function expandImage2(signedUrl, filename, setImageDivId) {
    const fullPage = document.getElementById('fullpage');
    fullPage.style.backgroundImage = "url('" + signedUrl +"')";
    fullPage.style.display = 'block';
    const convo = document.getElementById('conversation2')
    convo.style.display = 'none';
    setImageDivId(filename)
}

function AddImageFromS3({filename, index, idx, setImageDivId, isProtected, cognitoId}) {
     const [signedUrl, setSignedUrl] = useState("")
     useEffect(() => {
         async function getSignedUrl() {
             var url = ""
	     if (isProtected) {
		 url = await Storage.get(filename, {expires: 84600, level: "protected", identityId: cognitoId})
	     }
	     else {
		 url = await Storage.vault.get(filename, {expires: 84600})
	     }
             setSignedUrl(url)
     }
     getSignedUrl()
     }, [])
     if (index < 1 && idx < 4) {
         return <View id={filename} padding="medium" class="carousel-item">
		    <Image class="imgfit" width="auto" height="450px" objectFit="cover" src={signedUrl} onClick={() => expandImage(signedUrl, filename, setImageDivId)} style={{WebkitBorderRadius:"3%", overflow:"hidden", pointerEvents: "auto"}} />
		 </View>
     }
     return <View id={filename} padding="medium" class="carousel-item">
		    <Image class="imgfit" width="auto" height="450px" objectFit="cover" src={signedUrl} onClick={() => expandImage(signedUrl, filename, setImageDivId)} style={{WebkitBorderRadius:"3%", overflow:"hidden", pointerEvents: "auto"}} loading="lazy"/>
		 </View>
}

export default function AddImages({src, idx, setImageDivId, isProtected, cognitoId}) {
            let components = []
            for (let i = 0; i < src.length; i+=1) {
                components.push(<AddImageFromS3 filename={src[i]} index={i} idx={idx} setImageDivId={setImageDivId} isProtected={isProtected} cognitoId={cognitoId}/>)
        }
        return <View><Carousel showThumbs={false} showIndicators={true} style={{overflow: "hidden"}} autoPlay={false} infiniteLoop={false} >{components}</Carousel></View>
}
